import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import {
  trigger,
  style,
  transition,
  animate
} from '@angular/animations';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  animations: [
    trigger('popUpInOut', [
      transition('* => void', [animate(200, style({ opacity: 0 }))]),
      transition('void => *', [
        style({ opacity: 0 }),
        animate(200, style({ opacity: 1 }))
      ])
    ])
  ],
  encapsulation: ViewEncapsulation.None,
})
export class ModalComponent implements OnInit {
  @Input() content: string;
  @Input() modalClass: string;
  @Output() isModalOpen = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  hideModal(): void{
    this.isModalOpen.emit(false);
  }
}
