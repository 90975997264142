import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { autocompleteRequest } from './autocomplete-request';
import { BASE_PATH } from './api-client/variables';
import sigV4Client from './api-client/api/sigV4Client';

@Injectable({
  providedIn: 'root'
})
export class AddressAutocompleteService {
  objToSend: autocompleteRequest = {
    nr: '',
    zip: '',
    town: '',
    street: ''
  };

  constructor(private httpClient: HttpClient) {}

  public searchForAutocomplete(
    request: autocompleteRequest,
    amplifyResult: any
  ) {
    this.objToSend = request;

    // sprawdź czy w obj to send są jakieś dane, jeżeli nie to nie rób tego requesta, bo zwróci bład

    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const method = 'post';
    const path = `/billing/autofillAddress`;
    const queryParams = '';

    const signedRequest = sigV4Client
      .newClient({
        accessKey: amplifyResult.accessKeyId,
        secretKey: amplifyResult.secretAccessKey,
        sessionToken: amplifyResult.sessionToken,
        region: 'eu-central-1',
        endpoint: BASE_PATH
      })
      .signRequest({
        method,
        path,
        headersObject: headers,
        queryParams,
        body: this.objToSend
      });

    return this.httpClient.request<any>(method, signedRequest.url, {
      body: this.objToSend,
      headers: new HttpHeaders(signedRequest.headers)
      // observe: observe,
      // reportProgress: reportProgress
    });
  }
}
