import { Inject, Injectable, KeyValueDiffers, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';
import { AddSimOrderBody } from '../model/addSimOrderBody';
import { CreateUserBody } from '../model/createUserBody';
import { WebGetUsersResponse } from '../model/webGetUsersResponse';
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';
import { AmplifyService } from 'src/app/amplify/amplify.service';
import { promise } from 'protractor';
import sigV4Client from 'src/app/api-client/api/sigV4Client';
import { GetChildAccountsResponse } from 'getChildAccountsResponse';
var axios = require('axios');

@Injectable()
export class WebAppService {
  protected basePath = 'http://127.0.0.1:3000/';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  private amplifyResult: any;
  public accountBeingCreated: boolean = false;

  constructor(
    protected httpClient: HttpClient,
    // @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
    private amplifyService: AmplifyService
  ) {
    if (BASE_PATH) {
      this.basePath = BASE_PATH;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = BASE_PATH || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Add new SIM card order
   * Add new SIM card order
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public async webAddSimOrderPost(
    body: AddSimOrderBody,
    observe?: 'body',
    reportProgress?: boolean
  ): Promise<Observable<any>>;
  public async webAddSimOrderPost(
    body: AddSimOrderBody,
    observe?: 'response',
    reportProgress?: boolean
  ): Promise<Observable<HttpResponse<any>>>;
  public async webAddSimOrderPost(
    body: AddSimOrderBody,
    observe?: 'events',
    reportProgress?: boolean
  ): Promise<Observable<HttpEvent<any>>>;
  public async webAddSimOrderPost(
    body: AddSimOrderBody,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Promise<Observable<any>> {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling webAddSimOrderPost.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    const amplifyResult = await this.amplifyService.getCredentials();

    const headersObject = {};
    headers.keys().map(key => {
      headersObject[key] = headers.get(key);
      return;
    });

    const method = 'post';
    const path = `/web/simOrder`;
    const queryParams = '';

    const signedRequest = sigV4Client
      .newClient({
        accessKey: amplifyResult.accessKeyId,
        secretKey: amplifyResult.secretAccessKey,
        sessionToken: amplifyResult.sessionToken,
        region: 'eu-central-1',
        endpoint: this.basePath
      })
      .signRequest({
        method,
        path,
        headersObject,
        queryParams,
        body
      });

    return this.httpClient.request<any>(method, signedRequest.url, {
      body: body,
      // withCredentials: this.configuration.withCredentials,
      headers: new HttpHeaders(signedRequest.headers),
      observe: observe,
      reportProgress: reportProgress
    });
  }

  /**
   * Returns user status data
   * Returns user status data
   * @param cognitoId Cognito id of user
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public async webUsersCognitoIdGet(
    cognitoId: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Promise<Observable<WebGetUsersResponse>>;
  public async webUsersCognitoIdGet(
    cognitoId: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Promise<Observable<HttpResponse<WebGetUsersResponse>>>;
  public async webUsersCognitoIdGet(
    cognitoId: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Promise<Observable<HttpEvent<WebGetUsersResponse>>>;
  public async webUsersCognitoIdGet(
    cognitoId: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Promise<Observable<any>> {
    if (cognitoId === null || cognitoId === undefined) {
      throw new Error(
        'Required parameter cognitoId was null or undefined when calling webUsersCognitoIdGet.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    const consumes: string[] = [];

    const amplifyResult = await this.amplifyService.getCredentials();

    const serviceInfo = {
      region: 'eu-central-1',
      service: 'execute-api'
    };

    const headersObject = {};
    headers.keys().map(key => {
      headersObject[key] = headers.get(key);
      return;
    });

    const method = 'get';
    const path = `/web/users/${encodeURIComponent(String(cognitoId))}`;
    const queryParams = '';
    const body = null;

    const signedRequest = sigV4Client
      .newClient({
        accessKey: amplifyResult.accessKeyId,
        secretKey: amplifyResult.secretAccessKey,
        sessionToken: amplifyResult.sessionToken,
        region: 'eu-central-1',
        endpoint: this.basePath
      })
      .signRequest({
        method,
        path,
        headersObject,
        queryParams,
        body
      });

    return this.httpClient.request<WebGetUsersResponse>(
      method,
      signedRequest.url,
      {
        //withCredentials: this.configuration.withCredentials,
        headers: new HttpHeaders(signedRequest.headers),
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Create user
   * Create user
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public async webUsersPut(
    body: CreateUserBody,
    observe?: 'body',
    reportProgress?: boolean
  ): Promise<Observable<any>>;
  public async webUsersPut(
    body: CreateUserBody,
    observe?: 'response',
    reportProgress?: boolean
  ): Promise<Observable<HttpResponse<any>>>;
  public async webUsersPut(
    body: CreateUserBody,
    observe?: 'events',
    reportProgress?: boolean
  ): Promise<Observable<HttpEvent<any>>>;
  public async webUsersPut(
    body: CreateUserBody,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Promise<Observable<any>> {
    if (this.accountBeingCreated) {
      throw new Error('Account is being created');
    }

    this.accountBeingCreated = true;

    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling webUsersPut.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    const amplifyResult: any = await this.amplifyService.getCredentials();

    const method = 'put';
    const path = `/web/users`;
    const queryParams = '';

    const headersObject = {};
    headers.keys().map(key => {
      headersObject[key] = headers.get(key);
      return;
    });

    const signedRequest = sigV4Client
      .newClient({
        accessKey: amplifyResult.accessKeyId,
        secretKey: amplifyResult.secretAccessKey,
        sessionToken: amplifyResult.sessionToken,
        region: 'eu-central-1',
        endpoint: this.basePath
      })
      .signRequest({
        method,
        path,
        headersObject,
        queryParams,
        body
      });

    this.accountBeingCreated = false;

    return this.httpClient.request<any>(method, signedRequest.url, {
      body: body,
      // withCredentials: this.configuration.withCredentials,
      headers: new HttpHeaders(signedRequest.headers),
      observe: observe,
      reportProgress: reportProgress
    });
  }

  public async webWebBlockUnblockSimCardPost(
    body: any,
    observe?: 'body',
    reportProgress?: boolean
  ): Promise<Observable<any>>;
  public async webWebBlockUnblockSimCardPost(
    body: any,
    observe?: 'response',
    reportProgress?: boolean
  ): Promise<Observable<HttpResponse<any>>>;
  public async webWebBlockUnblockSimCardPost(
    body: any,
    observe?: 'events',
    reportProgress?: boolean
  ): Promise<Observable<HttpEvent<any>>>;
  public async webWebBlockUnblockSimCardPost(
    body: any,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Promise<Observable<any>> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    const amplifyResult: any = await this.amplifyService.getCredentials();

    const method = 'post';
    const path = `/web/webBlockUnblockSimCard`;
    const queryParams = '';

    const headersObject = {};
    headers.keys().map(key => {
      headersObject[key] = headers.get(key);
      return;
    });

    const signedRequest = sigV4Client
      .newClient({
        accessKey: amplifyResult.accessKeyId,
        secretKey: amplifyResult.secretAccessKey,
        sessionToken: amplifyResult.sessionToken,
        region: 'eu-central-1',
        endpoint: this.basePath
      })
      .signRequest({
        method,
        path,
        headersObject,
        queryParams,
        body
      });

    return this.httpClient.request<any>(method, signedRequest.url, {
      body: body,
      headers: new HttpHeaders(signedRequest.headers),
      observe: observe,
      reportProgress: reportProgress
    });
  }

  public async webWebGetChildAccountsGet(
    id: string,
    withSubscriptions: boolean,
    observe?: 'body',
    reportProgress?: boolean
  ): Promise<Observable<GetChildAccountsResponse>>;
  public async webWebGetChildAccountsGet(
    id: string,
    withSubscriptions: boolean,
    observe?: 'response',
    reportProgress?: boolean
  ): Promise<Observable<HttpResponse<GetChildAccountsResponse>>>;
  public async webWebGetChildAccountsGet(
    id: string,
    withSubscriptions: boolean,
    observe?: 'events',
    reportProgress?: boolean
  ): Promise<Observable<HttpEvent<GetChildAccountsResponse>>>;
  public async webWebGetChildAccountsGet(
    id: string,
    withSubscriptions: boolean,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Promise<Observable<any>> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling webWebGetChildAccountsGet.'
      );
    }

    if (withSubscriptions === null || withSubscriptions === undefined) {
      throw new Error(
        'Required parameter withSubscriptions was null or undefined when calling webWebGetChildAccountsGet.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (id !== undefined && id !== null) {
      queryParameters = queryParameters.set('id', <any>id);
    }
    if (withSubscriptions !== undefined && withSubscriptions !== null) {
      queryParameters = queryParameters.set(
        'withSubscriptions',
        <any>withSubscriptions
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    const headersObject = {};
    headers.keys().map(key => {
      headersObject[key] = headers.get(key);
      return;
    });

    const amplifyResult = await this.amplifyService.getCredentials();

    const method = 'get';
    const path = `/web/webGetChildAccounts`;
    const queryParams = {
      id: queryParameters.get('id'),
      withSubscriptions: queryParameters.get('withSubscriptions')
    };
    const body = null;

    const signedRequest = sigV4Client
      .newClient({
        accessKey: amplifyResult.accessKeyId,
        secretKey: amplifyResult.secretAccessKey,
        sessionToken: amplifyResult.sessionToken,
        region: 'eu-central-1',
        endpoint: this.basePath
      })
      .signRequest({
        method,
        path,
        headersObject,
        queryParams,
        body
      });

    return this.httpClient.get<GetChildAccountsResponse>(signedRequest.url, {
      headers: new HttpHeaders(signedRequest.headers),
      observe: observe,
      reportProgress: reportProgress
    });
  }
}
