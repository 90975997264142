import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  private order = new BehaviorSubject<any>({});
  public order$: Observable<any> = this.order.asObservable();

  constructor() {}

  public sendOrderData(orderData: any, esim: any): void {
    this.order.next({ orderData, esim });
  }
}
