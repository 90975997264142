import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LangaugeResolve } from './language.resolve';
import { LoadingComponent } from './loading/loading.component';
import { ToastsComponent } from './toasts/toasts.component';
import { AddressFormComponent } from './address-form/address-form.component';
import { TokenCheckResolve } from './token-check.resolve';
import { SplashComponent } from './splash/splash.component';
import { SiteFooterComponent } from './site-footer/site-footer.component';
import { CookieBannerComponent } from './cookie-banner/cookie-banner.component';
import { LanguageSwitcherComponent } from './language-switcher/language-switcher.component';
import { SiteHeaderComponent } from './site-header/site-header.component';
import { PhoneComponent } from './phone/phone.component';
import { AnimatedSimComponent } from './animated-sim/animated-sim.component';
import { LottieModule } from 'ngx-lottie';
import { InViewDirective } from './in-view.directive';
import { FaqsComponent } from './faqs/faqs.component';
import { SocialIconsComponent } from './social-icons/social-icons.component';
import { ScrollIndicatorComponent } from './scroll-indicator/scroll-indicator.component';
import { ImprintPageComponent } from './imprint-page/imprint-page.component';
import { SendDownloadLinkFormComponent } from './send-download-link-form/send-download-link-form.component';
import { ImageWithCTAComponent } from './image-with-cta/image-with-cta.component';
import { LazyTranslateLoader } from './lazy-translate-loader';
import { CallUsComponent } from './call-us/call-us.component';
import { CountdownComponent } from './countdown/countdown.component';
import { ReferrerTrackingResolve } from './referrer-tracking.resolve';
import { DownloadButtonComponent } from './download-button/download-button.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { IntroComponent } from './intro/intro.component';
import { PricingComponent } from './pricing/pricing.component';
import { BannerComponent } from './banner/banner.component';
import { FeatureComponent } from './feature/feature.component';
import { OrderSliceComponent } from './order-slice/order-slice.component';
import { HeroSliceComponent } from './hero-slice/hero-slice.component';
import { CustomContentComponent } from './custom-content/custom-content.component';
import { VideoComponent } from './video/video.component';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';
import { InfluencerTrackingResolve } from './influencer-tracking.resolve';
import { TooltipComponent } from './tooltip/tooltip.component';
import { ShowUrlParameterSliceComponent } from './show-url-parameter-slice/show-url-parameter-slice.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AddressPostalFormComponent } from './address-postal-form/address-postal-form.component';
import { AddressEmailFormComponent } from './address-email-form/address-email-form.component';
import { OrderConfirmationSliceComponent } from './order-confirmation-slice/order-confirmation-slice.component';
import { AmplifyUIAngularModule } from '@aws-amplify/ui-angular';
import { SignInComponent } from './sign-in/sign-in.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { TextFieldComponent } from './text-field/text-field.component';
import { OrDividerComponent } from './or-divider/or-divider.component';
import { SocialLoginComponent } from './social-login/social-login.component';
import { CheckYourEmailComponent } from './check-your-email/check-your-email.component';
import { RenewPasswordComponent } from './renew-password/renew-password.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NotifierModule, NotifierOptions } from 'angular-notifier';
import { DashboardComponent } from './dashboard/dashboard.component';
import { OrderComponent } from './order/order.component';
import { ApiModule } from './api-client';
import { SignUpComponent } from './sign-up/sign-up.component';
import { EmailVerificationComponent } from './email-verification/email-verification.component';
import { SpinnerOverlayComponent } from './spinner-overlay/spinner-overlay.component';
import { FaqLandingPageComponent } from './faq-landing-page/faq-landing-page.component';
import { EsimComponent } from './esim/esim.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { HelpButtonComponent } from './help-button/help-button.component';
import { RecaptchaModule } from 'ng-recaptcha';
import { NgSelectModule } from '@ng-select/ng-select';
import { BlockSimComponent } from './block-sim/block-sim.component';
import { OnboardingStepsComponent } from './onboarding-steps/onboarding-steps.component';
import { UserSubscriptionsComponent } from './user-subscriptions/user-subscriptions.component';
import { UnblockSimComponent } from './unblock-sim/unblock-sim.component';
import { GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';
import { ModalComponent } from './modal/modal.component';
import { SuspendedAccountComponent } from './suspended-account/suspended-account.component';

declare module '@angular/core' {
  interface ModuleWithProviders<T = any> {
    ngModule: Type<T>;
    providers?: Provider[];
  }
}

// Note we need a separate function as it's required
// by the AOT compiler.
export function playerFactory() {
  return import('lottie-web');
}

const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'right',
      distance: 12
    },
    vertical: {
      position: 'top',
      distance: 80,
      gap: 10
    }
  },
  theme: 'material',
  behaviour: {
    autoHide: 5000,
    onClick: 'hide',
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease'
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50
    },
    shift: {
      speed: 300,
      easing: 'ease'
    },
    overlap: 150
  }
};
@NgModule({
  declarations: [
    AppComponent,
    LoadingComponent,
    ToastsComponent,
    AddressFormComponent,
    SplashComponent,
    SiteFooterComponent,
    CookieBannerComponent,
    LanguageSwitcherComponent,
    SiteHeaderComponent,
    PhoneComponent,
    AnimatedSimComponent,
    InViewDirective,
    FaqsComponent,
    SocialIconsComponent,
    ScrollIndicatorComponent,
    ImprintPageComponent,
    SendDownloadLinkFormComponent,
    ImageWithCTAComponent,
    CallUsComponent,
    CountdownComponent,
    DownloadButtonComponent,
    LandingPageComponent,
    IntroComponent,
    PricingComponent,
    BannerComponent,
    FeatureComponent,
    OrderSliceComponent,
    HeroSliceComponent,
    CustomContentComponent,
    VideoComponent,
    NotFoundPageComponent,
    TooltipComponent,
    ShowUrlParameterSliceComponent,
    AddressEmailFormComponent,
    AddressPostalFormComponent,
    OrderConfirmationSliceComponent,
    SignInComponent,
    ForgotPasswordComponent,
    TextFieldComponent,
    OrDividerComponent,
    SocialLoginComponent,
    CheckYourEmailComponent,
    RenewPasswordComponent,
    DashboardComponent,
    OrderComponent,
    SignUpComponent,
    EmailVerificationComponent,
    SpinnerOverlayComponent,
    FaqLandingPageComponent,
    EsimComponent,
    ThankYouComponent,
    HelpButtonComponent,
    BlockSimComponent,
    OnboardingStepsComponent,
    UserSubscriptionsComponent,
    UnblockSimComponent,
    ModalComponent,
    SuspendedAccountComponent
  ],
  imports: [
    BrowserModule,
    RecaptchaModule,
    FormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AmplifyUIAngularModule,
    HttpClientModule,
    NotifierModule.withConfig(customNotifierOptions),
    LottieModule.forRoot({ player: playerFactory, useCache: true }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: LazyTranslateLoader
      }
    }),
    FontAwesomeModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    ApiModule,
    NgSelectModule,
    FormsModule
  ],
  providers: [
    LangaugeResolve,
    TokenCheckResolve,
    ReferrerTrackingResolve,
    InfluencerTrackingResolve,
    SocialAuthService,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: true, //keeps the user signed in
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '432460877181-362se831l2jfkon5a6spq67lrihve6rh'
            ) // your client id
          }
        ]
      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
