import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-block-sim',
  templateUrl: './block-sim.component.html',
  styleUrls: ['./block-sim.component.scss'],
  animations: [
    trigger('showWarning', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateX(100%)' }),
        animate(
          '450ms ease-in-out',
          style({ opacity: 1, transform: 'translateX(0)' })
        )
      ])
    ])
  ]
})
export class BlockSimComponent implements OnInit {
  blockConfirmation: boolean = false;

  @Input()
  subscription;

  @Output()
  close = new EventEmitter<boolean>();

  @Output()
  simBlocked = new EventEmitter<boolean>();

  constructor() {}

  blockSim() {
    if (this.blockConfirmation !== true) {
      return;
    }
    this.simBlocked.emit(true);
    this.closePopup();
  }

  closePopup() {
    this.close.emit(true);
  }

  ngOnInit(): void {}
}
