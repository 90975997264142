<div class="page-wrapper">
  <div class="page main full-height">
    <app-site-header [showCallUs]="false"></app-site-header>
    <div class="page-content">
      <div class="content">
        <div [style]="{width: logoSize * 2+'px'}" class="logo" (click)="animateLogo()">
          <ng-lottie [options]="lottieConfig" [height]="logoSize + 'px'" (animationCreated)="handleAnimation($event)"
            [styles]="styles">
          </ng-lottie>
        </div>
        <h1 translate="NOT_FOUND_TITLE">
        </h1>
        <p translate="NOT_FOUND_DETAILS"></p>
        <div>
          <p translate="HELPFUL_LINKS_TEXT"></p>
          <ul>
            <li><a routerLink="/" translate="MENUITEMHOME"></a></li>
            <li><a routerLink="/pre-order" translate="MENUITEMGETFREESIM"></a></li>
            <li>
              <app-download-button [desktopCtaText]="'MENUITEMAPP' | translate"
                [mobileCtaText]="'TRYFORFREE' | translate" [buttonExtraClass]="'big'">
              </app-download-button>
            </li>
          </ul>
        </div>
      </div>
      <app-help-button></app-help-button>
      <app-site-footer></app-site-footer>
    </div>
  </div>
</div>