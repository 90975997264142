import { trigger, transition, style, animate } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { AnimationOptions } from 'ngx-lottie';
import { ApiService } from '../api.service';
import { CustomerData } from '../customer-data';
import { LanguageService } from '../language.service';
import { OrderConfirmationSlice } from '../order-confirmation-slice';
import { ResizeService } from '../resize.service';
import { TrackingService } from '../tracking.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-order-confirmation-slice',
  templateUrl: './order-confirmation-slice.component.html',
  styleUrls: ['./order-confirmation-slice.component.scss'],
  animations: [
    trigger('content', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(25%)' }),
        animate('500ms', style({ opacity: 0, transform: 'translateY(25%)' })),
        animate(
          '250ms ease-out',
          style({ opacity: 1, transform: 'translateY(0%)' })
        )
      ])
    ])
  ]
})
export class OrderConfirmationSliceComponent implements OnInit {
  @Input() sliceData: OrderConfirmationSlice;
  content: SafeHtml;

  data: CustomerData;
  showContent = false;
  lottieConfig: AnimationOptions;
  lottieSize = window.innerWidth > 700 ? '180px' : '140px';
  canShare: boolean;
  styles: Partial<CSSStyleDeclaration> = {
    margin: '0 auto'
  };
  currentLang: string;

  constructor(
    private api: ApiService,
    private translate: TranslateService,
    private lang: LanguageService,
    private tracking: TrackingService,
    private sanitizer: DomSanitizer,
    public router: Router
  ) {
    this.data = this.api.customerData;
    setTimeout(() => (this.showContent = true));
    this.lottieConfig = {
      path: 'assets/lottie/check.json',
      renderer: 'canvas',
      loop: false,
      autoplay: true
    } as any;
    let newVariable: any;
    newVariable = window.navigator;
    this.canShare = !!newVariable.share;
    this.currentLang = this.lang.current;
  }

  ngOnInit() {
    let c = this.sliceData.text
      .replace('{{name}}', this.data?.address?.firstName || '')
      .replace('{{email}}', this.data?.email || '')
      .replace(
        '{{street}}',
        (this.data?.address?.street || '') +
          ' ' +
          (this.data?.address?.streetNumber || '')
      )
      .replace('{{zip}}', this.data?.address?.zip || '')
      .replace('{{city}}', this.data?.address?.city || '');
    this.content = this.sanitizer.bypassSecurityTrustHtml(c);

    if (!this.api.customerData) {
      // if this object isn't present then it means the confirmation page is being refreshed
      // therefore redirect to the initial pre-order page
      this.router.navigate([this.lang.current, 'pre-order']);
    }
  }

  openAppStore(store: 'google' | 'apple') {
    this.tracking.ctaClick('download link ' + store);
    setTimeout(() => {
      let link = this.tracking.getDownloadLinkForStore(store);
      window.open(link, '_blank');
    }, 250);
  }

  share() {
    let newVariable: any;
    newVariable = window.navigator;
    if (newVariable.share) {
      newVariable
        .share({
          title: this.translate.instant('SHARETITLE'),
          text: this.translate.instant('SHAREDESCRIPTION'),
          url: location.origin + '/start'
        })
        .then(() => console.log('Successful share'))
        .catch((error: string) => {
          console.log('Error sharing', error);
          this.shareFallBack();
        });
    } else {
      this.shareFallBack();
    }
  }

  shareFallBack() {
    alert('not supported');
  }

  isSuccessPage() {
    return (
      this.api.customerData &&
      (this.api.customerData.validationCode === '' ||
        !this.api.customerData.validationCode)
    );
  }
}
