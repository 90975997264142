import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-faq-landing-page',
  templateUrl: './faq-landing-page.component.html',
  styleUrls: ['./faq-landing-page.component.scss']
})
export class FaqLandingPageComponent implements OnInit {
  @Input() sliceData;
  currentLang: string;

  constructor(
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.currentLang = this.route.snapshot.params['lang'];
  }

}
