import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { FormControlName, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { threadId } from 'worker_threads';
import { autocompleteOption } from '../autocomplete-request';

@Component({
  selector: 'text-field',
  templateUrl: './text-field.component.html',
  styleUrls: ['./text-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextFieldComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextFieldComponent implements OnInit {
  constructor(private changeDetectorRef: ChangeDetectorRef) { }

  private _value: any;

  @Input()
  set value(value: any) {
    this._value = value;
    this.onChange(value);
  }
  get value(): any {
    return this._value;
  }

  onChange = event => { };
  onTouched = () => { };

  focused: boolean = false;

  @Input()
  disabled: boolean = false;

  @Input()
  error: boolean;

  @Input()
  authentication: boolean;

  @Input()
  placeholder: string = '';

  @Input()
  required: boolean = false;

  @Input()
  labelText: string = '';

  @Input()
  labelClass: string;

  @Input()
  type: string = 'text';

  @Input()
  formGroup: FormGroup;

  @Input()
  formControlName: FormControlName;

  @Input()
  autocompleteResults: object;

  @Input()
  list: string;

  @Output()
  autocompleteEmitter = new EventEmitter();

  autocompleteSuggestions: autocompleteOption = {
    street: '',
    town: '',
    plz: ''
  };

  autocomplete: boolean = false;

  isPasswordType: boolean = false;

  showAutocomplete(event) {
    if (event.target.value != "") {
      this.autocomplete = true;
    } else {
      this.autocomplete = false;
    }
  }

  onFocus() {
    this.focused = true;
  }

  onFocusOut() {
    this.focused = false;
  }

  ngOnInit(): void {
    this.isPasswordType = this.type === 'password';
  }

  onToggleShowPassword() {
    this.type = this.type === 'password' ? 'text' : 'password';
  }

  writeValue(value: any) {
    this.value = value;
    if (this.changeDetectorRef) {
      this.changeDetectorRef.markForCheck();
    }
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }
}
