<div class="overlay"></div>
<div class="popup-container popup-error" [@showWarning]>
  <div class="checkbox-container">
    <img src="/assets/icons/icon_error.svg" alt="" class="icon" />
    <span class="info">{{ 'BLOCK_SIM_WARNING' | translate }}</span>
  </div>
  <div>
    <input
      type="checkbox"
      id="block"
      name="block"
      [checked]="blockConfirmation"
      (change)="blockConfirmation = !blockConfirmation"
    />
    <span class="info">{{ 'BLOCK_SIM_CHECKBOX' | translate }}</span>
  </div>
  <div class="buttons-container">
    <button
      [disabled]="!blockConfirmation"
      class="transparent-button"
      (click)="blockSim()"
    >
      {{ 'OK' | translate }}
    </button>
    <button class="transparent-button" (click)="closePopup()">
      {{ 'CLOSE' | translate }}
    </button>
  </div>
</div>
