import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import { AmplifyService } from '../amplify/amplify.service';
import { WebAppService } from '../api-client';
import { TrackingService } from '../tracking.service';
import { AddressAutocompleteService } from '../address-autocomplete.service';
import { Subscription } from 'rxjs';
import { autocompleteOption } from '../autocomplete-request';
import { OrderService } from '../order.service';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {
  order: any;
  model: any;
  agreed: boolean;
  valid: boolean;
  userData: any;
  loading: boolean = false;
  currentLanguage: string;
  recaptchaPassed: boolean = false;
  autocompleteSubscription: Subscription;
  orderData: Subscription;
  autocompleteResults: autocompleteOption[] = [];
  amplifyResult: any;

  constructor(
    private webAppService: WebAppService,
    private amplify: AmplifyService,
    private route: ActivatedRoute,
    private router: Router,
    private notifier: NotifierService,
    private translate: TranslateService,
    private tracking: TrackingService,
    private autocomplete: AddressAutocompleteService,
    private orderService: OrderService
  ) {
    this.order = {};
    this.model = {};
    this.agreed = false;
    this.valid = false;
    this.getUserData();
  }

  debounce(callback, wait) {
    let timeoutId = null;
    return (...args) => {
      window.clearTimeout(timeoutId);
      timeoutId = window.setTimeout(() => {
        callback.apply(null, args);
      }, wait);
    };
  }

  async getUserData() {
    this.loading = true;
    const user = await this.amplify.getCurrentUser();

    if (!user) {
      this.loading = false;
      const lang = this.route.snapshot.params['lang'];
      this.router.navigate(['/' + lang + '/sign-up']);
    }

    (
      await this.webAppService.webUsersCognitoIdGet(user.attributes.sub)
    ).subscribe(
      (data: any) => {
        this.loading = false;
        if (data.data.__ON_BOARDING_STEP !== 'OFFER') {
          this.router.navigate(['/' + this.currentLanguage + '/sign-in']);
        }
        this.userData = data;
      },
      async error => {
        this.loading = false;
        console.log(error);
        console.log(error.status);
      }
    );
  }

  onClear(type) {
    this.order[type] = undefined;
    this.autocompleteChange(type, undefined);
    this.model = {};
    this.order.number = undefined;
    this.order.town = undefined;
    this.order.plz = undefined;
    this.order.street = undefined;
  }

  reactToChanges({ number, street, town, plz }) {
    const autocompleteRequest = {
      nr: number,
      street,
      town,
      zip: plz
    };

    this.autocompleteSubscription = this.autocomplete
      .searchForAutocomplete(autocompleteRequest, this.amplifyResult)
      .subscribe(autocomplete => {
        this.autocompleteResults = [];

        for (const result of autocomplete.data) {
          let autocompleteOption: autocompleteOption | any = {
            street: result.Streetname,
            town: result.TownName,
            plz: result.Zipcode,
            number: result.HouseNumber
          };

          this.autocompleteResults.push({
            ...autocompleteOption,
            label: this.generateLabel(autocompleteOption)
          });
          this.autocompleteResults = [...new Set(this.autocompleteResults)];
        }
      });
  }

  generateLabel({ town, number, plz, street }) {
    let result = '';
    if (town) {
      result += town;
    }
    if (street && number) {
      result += ' ' + street;
      result += ', ' + number;
    } else {
      if (street) {
        result += ' ' + street;
      }
    }

    if (plz) {
      result += ', ' + plz;
    }

    return result;
  }

  async ngOnInit() {
    this.amplifyResult = await this.amplify.getCredentials();
    this.tracking.ctaClick('website sim order form viewed');
    this.currentLanguage = this.route.snapshot.params['lang'];

    this.amplify.getCurrentUser().then(data => {
      if (!data) {
        this.notifier.notify(
          'error',
          this.translate.instant('PASSWORD_PROCESS_RESET_NOT_STARTED')
        );
        setTimeout(() => {
          const lang = this.route.snapshot.params['lang'];
          this.router.navigate(['/' + lang + '/sign-in']);
        }, 1000);
      }
    });
  }

  resolved(event) {
    console.log(event);
    this.recaptchaPassed = true;
    this.validate();
  }

  debouncedReactToChanges = this.debounce(arg => {
    this.reactToChanges(arg);
  }, 300);

  autocompleteChange(type, value) {
    this.debouncedReactToChanges({ ...this.order, [type]: value } as any);
  }

  autocompleteSelect(type, value) {
    for (let key in value) {
      this.model[key] = {
        label: value[key]
      };
      if (key !== 'label') {
        this.order[key] = value[key];
      }
    }

    if (value) {
      this.model[type] = {
        label: value[type]
      };

      this.order[type] = value[type];
    }

    this.debouncedReactToChanges({ ...this.order, [type]: value[type] } as any);
    this.validate();
  }

  async submit(event, esim) {
    this.loading = true;
    this.validate();
    if (!this.valid) {
      this.loading = false;
      this.notifier.notify('error', this.translate.instant('FORM_HAS_ERRORS'));
      return;
    }

    (
      await this.webAppService.webAddSimOrderPost(
        this.dehydrateOrder(this.order, esim)
      )
    ).subscribe(
      data => {
        this.tracking.ctaClick('website sim ordered');
        this.loading = false;
        this.valid = false;
        this.notifier.notify('success', this.translate.instant('ORDER_PLACED'));
        setTimeout(() => {
          this.orderService.sendOrderData(this.order, esim);
          const lang = this.route.snapshot.params['lang'];
          this.router.navigate(['/' + lang + '/thank-you']);
        }, 2000);
      },
      error => {
        console.log(error);
        if (!error.error.success) {
          this.loading = false;
          this.notifier.notify(
            'error',
            this.translate.instant('INVALID_ADDRESS')
          );
        }
      }
    );
  }

  dehydrateOrder(order, esim) {
    return {
      id: this.userData.data.id,
      firstName: order.firstName,
      lastName: order.lastName,
      street: order.street,
      nr: order.number,
      plz: order.plz,
      city: order.city || order.town,
      company: order.company,
      orderSimCard: !esim
    };
  }

  validate() {
    if (!this.recaptchaPassed) {
      this.valid = false;
      return;
    }

    for (let key in this.order) {
      if (this.order[key].includes(',') || this.order[key].length > 55) {
        this.valid = false;
        return;
      }
    }

    const keys = Object.keys(this.order);
    const expectedKeys = [
      'city',
      'firstName',
      'lastName',
      'street',
      'plz',
      'number'
      // 'company' <- not validated
    ].sort();

    const matching = expectedKeys.map(expectedKey =>
      keys.includes(expectedKey)
    );

    if (
      keys &&
      keys.length >= expectedKeys.length &&
      this.agreed &&
      matching.length === expectedKeys.length
    ) {
      this.valid = true;
      return;
    }

    this.valid = false;
  }

  onFieldUpdate(event, key) {
    this.order[key] = event.target.value;

    if (!event.target.value) {
      delete this.order[key];
      this.validate();
    }

    this.validate();
  }

  updateAgreementStatus() {
    this.agreed = !this.agreed;
    this.validate();
  }
}
