<div></div>
<div class="page-wrapper">
  <div class="page-content">
    <app-site-header></app-site-header>
    <notifier-container></notifier-container>
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-5 d-none d-xl-block" align="center">
          <div class="hero-container">
            <!-- <img
              class="hero-background"
              src="assets/icons/swype-hero-bg.png"
              alt="background"
            /> -->
            <img
              class="hero-card"
              src="assets/icons/swype-hero-card2.png"
              alt="background"
            />
          </div>
        </div>

        <div class="col-lg-7 col-md-12">
          <div class="row">
            <img
              *ngIf="!this.order.esim"
              style="display: block; margin: 50px auto 20px auto;"
              src="assets/icons/email-icon.svg"
              alt=""
            />
            <img
              *ngIf="this.order.esim"
              style="display: block; margin: 50px auto 20px auto;"
              src="assets/icons/icon_esim_thank_you_page.png"
              alt=""
            />
          </div>
          <div class="row justify-content-center">
            <div class="title">
              {{ "THANK_YOU" | translate }} {{ this.order.firstName }}
              {{ this.order.lastName }}!
            </div>
          </div>
          <div *ngIf="!this.order.esim" class="row justify-content-center">
            <div class="subtitle">
              {{ "SIM_DELIVERY_MESSAGE" | translate }}:
            </div>
          </div>
          <div *ngIf="!this.order.esim" class="row justify-content-center">
            <div class="address">{{ "ADDRESS" | translate }}:</div>
          </div>
          <div *ngIf="!this.order.esim" class="row justify-content-center">
            <div class="address-value">
              {{ this.order.street }} {{ this.order.number }},
              {{ this.order.plz }} {{ this.order.city }}
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="phone-photo">
              <img
                style="margin-top: 10px;"
                src="assets/icons/mobile_just_better_phone.png"
                alt=""
              />
            </div>
          </div>
          <div class="row justify-content-center center-content">
            <ul *ngIf="!this.order.esim" class="sum-up-title">
              {{ "ADDRESS_CHECKOUT" | translate }}
              <li>{{ "ADDRESS_CHECKOUT_STEP1" | translate }}</li>
              <li>{{ "ADDRESS_CHECKOUT_STEP2" | translate }}</li>
              <li>{{ "ADDRESS_CHECKOUT_STEP3" | translate }}</li>
              <li>{{ "ADDRESS_CHECKOUT_STEP4" | translate }}</li>
            </ul>
            <div *ngIf="this.order.esim" class="sum-up-title">{{ "PLEASE_COMPLETE_ESIM_PROCESS" | translate }}
            </div>
            <div class="sum-up-subtitle">
              {{ "FIRST_MOBILE_CONTRACT" | translate }}
            </div>

            <div class="store-buttons-container">
              <img
                (click)="openAppStore('apple')"
                src="../../assets/icons/app-store-badge-{{ lang.current }}.svg"
                alt=""
                class="app-store"
              />
              <img
                (click)="openAppStore('google')"
                src="../../assets/icons/google-play-badge-{{
                  lang.current
                }}.png"
                alt=""
                class="google-play"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-help-button></app-help-button>
  <div class="page no-gradient">
    <app-site-footer></app-site-footer>
  </div>
</div>

<spinner-overlay [showspinner]="loading"></spinner-overlay>
