<div
  class="slice"
  [class]="{
    primary: sliceData.backgroundColor == 'red',
    white: sliceData.backgroundColor == 'white',
    lighter: sliceData.backgroundColor == 'gray',
    'big-spacing': sliceData.spacing == 'default',
    headless: sliceData.spacing == 'headless',
    bottomless: sliceData.spacing == 'bottomless'
  }"
  [attr.id]="sliceData.id"
>
  <div class="content">
    <div class="content-splitter">
      <div class="left-split-container">
        <div class="logo-container">
          <img src="/assets/icons/icon_esim_red.png" class="esim-logo" alt="" />
        </div>
        <div *ngFor="let item of sliceData.items | slice: 0:1">
          <h1 class="title" [innerHTML]="item.title"></h1>
          <p class="item-text" [innerHTML]="item.text"></p>
        </div>
      </div>
      <div class="right-split-container">
        <div class="logo-container feature-container">
          <img
            src="/assets/icons/swype_small_icon.svg"
            class="swype-logo"
            alt=""
          />
          <span class="feature-text" [innerHTML]="sliceData.featureText"></span>
        </div>
        <div class="right-split-content">
          <div
            *ngFor="let item of sliceData.items | slice: 1:3"
            class="split-content"
          >
            <div class="title" [innerHTML]="item.title"></div>
            <p class="item-text" [innerHTML]="item.text"></p>
          </div>
        </div>
        <div
          *ngFor="let item of sliceData.items | slice: 3:4"
          class="content-splitter"
        >
          <div>
            <div class="title" [innerHTML]="item.title"></div>
            <p class="item-text" [innerHTML]="item.text"></p>
          </div>
          <div class="store-buttons-container">
            <img
              (click)="openAppStore('apple')"
              src="../../assets/icons/app-store-badge-{{ lang.current }}.svg"
              alt=""
              class="app-store"
            />
            <img
              (click)="openAppStore('google')"
              src="../../assets/icons/google-play-badge-{{ lang.current }}.png"
              alt=""
              class="google-play"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
