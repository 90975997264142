import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ForgotPassword } from '../DTO/ForgotPassword';
import { NotifierService } from 'angular-notifier';
import { ActivatedRoute, Router } from '@angular/router';
import { AmplifyService } from '../amplify/amplify.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-renew-password',
  templateUrl: './renew-password.component.html',
  styleUrls: ['./renew-password.component.scss']
})
export class RenewPasswordComponent implements OnInit {
  myForm: FormGroup;
  value: string;
  minimumEightCharacters: boolean;
  oneLowerCaseLetter: boolean;
  oneUpperCaseLetter: boolean;
  atLeastOneDigit: boolean;
  oneSpecialSymbol: boolean;
  confirmationMatchesPassword: boolean;
  password: string;
  confirmation: string;
  valid: boolean;
  loading: boolean = false;

  model = new ForgotPassword('');

  constructor(
    private notifier: NotifierService,
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private amplify: AmplifyService,
    private translate: TranslateService
  ) {
    this.minimumEightCharacters = false;
    this.oneLowerCaseLetter = false;
    this.oneUpperCaseLetter = false;
    this.atLeastOneDigit = false;
    this.oneSpecialSymbol = false;
    this.confirmationMatchesPassword = false;
    this.valid = false;
  }

  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      password: ['Default value'],
      passwordConfirm: ['Default value']
    });

    if (!localStorage.getItem('email') || !localStorage.getItem('code')) {
      this.showNotification(
        'error',
        this.translate.instant('PASSWORD_PROCESS_RESET_NOT_STARTED')
      );
      setTimeout(() => {
        const lang = this.route.snapshot.params['lang'];
        this.router.navigate(['/' + lang + '/sign-in']);
      }, 1000);
    }
  }

  returnToSignIn() {
    const lang = this.route.snapshot.params['lang'];
    this.router.navigate(['/' + lang + '/sign-in']);
  }

  hasLowerCase(str) {
    return /[a-z]/.test(str);
  }

  hasUpperCase(str) {
    return /[A-Z]/.test(str);
  }

  hasAtLeastOneDigit(str) {
    return /\d/.test(str);
  }

  hasSymbol(str) {
    return /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(str);
  }

  checkValidation() {
    if (
      this.minimumEightCharacters &&
      this.oneLowerCaseLetter &&
      this.oneUpperCaseLetter &&
      this.atLeastOneDigit &&
      this.oneSpecialSymbol &&
      this.confirmationMatchesPassword
    ) {
      this.valid = true;
    } else {
      this.valid = false;
    }
  }

  onInput(event) {
    const value = event.target.value;
    this.password = value;

    this.minimumEightCharacters = value.length > 7;
    this.oneLowerCaseLetter = this.hasLowerCase(value);
    this.oneUpperCaseLetter = this.hasUpperCase(value);
    this.atLeastOneDigit = this.hasAtLeastOneDigit(value);
    this.oneSpecialSymbol = this.hasSymbol(value);
    this.confirmationMatchesPassword = this.password === this.confirmation;

    this.checkValidation();
  }

  onInputConfirmation(event) {
    const value = event.target.value;
    this.confirmation = value;
    this.confirmationMatchesPassword = this.password === this.confirmation;
    this.checkValidation();
  }

  async submit(event) {
    this.loading = true;
    if (!this.valid) {
      this.loading = false;
      return;
    }

    const result = await this.amplify.finishRecovery(
      localStorage.getItem('email'),
      localStorage.getItem('code'),
      this.password
    );

    localStorage.removeItem('email');
    localStorage.removeItem('code');

    if (!result) {
      this.loading = false;
      this.valid = false;
      this.showNotification(
        'success',
        this.translate.instant('PASSWORD_CHANGED')
      );
      setTimeout(() => {
        this.router.navigate(['/en/sign-in']);
      }, 1000);
      return;
    }

    if (result.code === 'CodeMismatchException') {
      this.loading = false;
      this.showNotification(
        'error',
        this.translate.instant('INVALID_VERIFICATION_CODE')
      );
      const lang = this.route.snapshot.params['lang'];
      setTimeout(() => {
        this.router.navigate(['/' + lang + '/forgot-password']);
      }, 1000);
      return;
    }
  }
}
