import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import {
  trigger,
  state,
  style,
  transition,
  animate
} from '@angular/animations';
import { PricingSlice } from '../pricing-slice';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss'],
  encapsulation: ViewEncapsulation.None, //to be able to style content inserted via innerHTML
  animations: [
    trigger('feature', [
      state('inview', style({ transform: window.innerWidth > 700 ? 'translateY(0)' : 'translateY(0%)', opacity: 1 })),
      state('outofview', style({
        transform: window.innerWidth > 700 ? 'translateY(25%)' : 'translateY(0%)',
        opacity: window.innerWidth > 700 ? 0 : 1
      })),
      transition('outofview => inview', animate('0.5s ease-out'))
    ])
  ]
})
export class PricingComponent implements OnInit {
  @Input() sliceData: PricingSlice;
  monthlyInView = false;
  dailyInView = false;
  dataInView = false;
  splitterInView = false;
  paymentMethodsInView = false;
  showCountries = false;
  isMobileDevice = false;
  isModalOpen = false;
  isRoamingPath = false;
  modalContent = "";
  modalClass = "";

  constructor() {
   }

  ngOnInit(): void {
    if (window.location.pathname.includes('/roaming')) {
      this.isRoamingPath = true;
    }
  }

  openModal(content, modalClass){
    this.modalClass = modalClass;
    this.modalContent = content;
    this.isModalOpen = true;
  }

  hideModal(event): void{
    this.modalContent = '';
    this.isModalOpen = event;
  }
}
