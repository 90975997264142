<div class="page-wrapper">
  <div class="page-content">
    <app-site-header></app-site-header>
    <notifier-container></notifier-container>
    <div class="container-fluid">
      <img style="display: block; margin: 0px auto 20px auto;" src="assets/icons/email-icon.svg" alt="">
      <div class="title">
        <div class="main-title">{{ "CHECK_YOUR_EMAIL" | translate }}</div>
        <div class="subtitle">{{ "PRESS_LINK_TO_CONFIRM" | translate }}</div>
      </div>
      <div class="form-container">
        <img class="resend-code" (click)="resendConfirmationCode()"
          style="margin: 10px auto; display: block; cursor: pointer;" src="assets/icons/resend.svg" alt="divider"
          srcset="">
        <text-field *ngIf="!emailPresent" class="email-input" style="margin: 10px auto; display: block;"
          [placeholder]="'YOUR_EMAIL_ADDRESS' | translate" label="'EMAIL' | translate"
          (paste)="onPaste($event, 'email')" (keyup)="onEmailInput($event)" medium=true></text-field>
        <div class="letter-input-container">
          <input autocomplete="off" class="letter-input" (paste)="onPasteCode($event)"
            (keyup)="onDigitInput($event, false)" maxlength="1" type="text">
          <input autocomplete="off" class="letter-input" (keyup)="onDigitInput($event, false)" maxlength="1"
            type="text">
          <input autocomplete="off" class="letter-input" (keyup)="onDigitInput($event, false)" maxlength="1"
            type="text">
          <input autocomplete="off" class="letter-input" (keyup)="onDigitInput($event, false)" maxlength="1"
            type="text">
          <input autocomplete="off" class="letter-input" (keyup)="onDigitInput($event, false)" maxlength="1"
            type="text">
          <input autocomplete="off" class="letter-input" (keyup)="onDigitInput($event, true)" maxlength="1" type="text">
        </div>
        <div class="button-wrapper">
          <button (click)="activateAccount()" class="sign-in-button" [ngClass]="{ 'active': valid }">
            {{ "ACTIVATE_ACCOUNT" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <app-help-button></app-help-button>
  <div class="page no-gradient">
    <app-site-footer></app-site-footer>
  </div>
</div>
<spinner-overlay [showspinner]=loading></spinner-overlay>