import { Component, Input, OnInit } from '@angular/core';
import { EsimSlice } from '../esim-slice';
import { TrackingService } from '../tracking.service';
import { LanguageService } from '../language.service';

@Component({
  selector: 'app-esim',
  templateUrl: './esim.component.html',
  styleUrls: ['./esim.component.scss']
})
export class EsimComponent implements OnInit {
  @Input() sliceData: EsimSlice;

  constructor(
    private tracking: TrackingService,
    public lang: LanguageService
  ) {}

  openAppStore(store: 'google' | 'apple') {
    this.tracking.ctaClick('download link ' + store);
    setTimeout(() => {
      let link = this.tracking.getDownloadLinkForStore(store);
      window.open(link, '_blank');
    }, 250);
  }

  ngOnInit(): void {
  }
}
