import { Component, Input, OnInit } from '@angular/core';
import { TrackingService } from '../tracking.service';
import { LanguageService } from '../language.service';

@Component({
  selector: 'app-onboarding-steps',
  templateUrl: './onboarding-steps.component.html',
  styleUrls: ['./onboarding-steps.component.scss']
})
export class OnboardingStepsComponent implements OnInit {
  steps: {}[];

  @Input()
  activeStep;

  @Input()
  onBoardingStep;

  constructor(private tracking: TrackingService, public lang: LanguageService) {
    this.steps = [
      {
        title: 'STEP1',
        subtitle: 'STEP1_SUBTITLE',
        done_subtitle: 'STEP1_DONE_SUBTITLE'
      },
      {
        title: 'STEP2',
        subtitle: 'STEP2_SUBTITLE',
        done_subtitle: 'STEP2_DONE_SUBTITLE'
      },
      {
        title: 'STEP3',
        subtitle: 'STEP3_SUBTITLE',
        done_subtitle: 'STEP3_DONE_SUBTITLE'
      },
      {
        title: 'STEP4',
        subtitle: 'STEP4_SUBTITLE',
        done_subtitle: 'STEP4_DONE_SUBTITLE'
      },
      {
        title: 'STEP5',
        subtitle: 'STEP5_SUBTITLE',
        done_subtitle: 'STEP5_DONE_SUBTITLE'
      }
    ];
  }

  openAppStore(store: 'google' | 'apple') {
    this.tracking.ctaClick('download link ' + store);
    setTimeout(() => {
      let link = this.tracking.getDownloadLinkForStore(store);
      window.open(link, '_blank');
    }, 250);
  }

  ngOnInit(): void {
  }
}
