<div class="overlay"></div>
<div class="popup-container popup-success" [@showWarning]>
  <div class="checkbox-container">
    <img src="/assets/icons/icon_error.svg" alt="" class="icon" />
    <span class="info">{{ 'UNBLOCK_SIM_WARNING' | translate }}</span>
  </div>
  <div class="buttons-container">
    <button class="transparent-button" (click)="unblockSim()">
      {{ 'OK' | translate }}
    </button>
    <button class="transparent-button" (click)="closePopup()">
      {{ 'CLOSE' | translate }}
    </button>
  </div>
</div>
