<div class="slice" [class]="{
    'primary': sliceData.backgroundColor == 'red', 
    'white': sliceData.backgroundColor == 'white', 
    'lighter': sliceData.backgroundColor == 'gray',
    'big-spacing': sliceData.spacing == 'default'
    }" id="{{sliceData.id}}">
    <div class="content center-content">
        <div class="content-splitter">
            <div class="split">
                <img src="assets/swype-logo-gradient-red.png" width="128" height="200" alt="">
            </div>
            <div class="split">
                <h1 class="title" [innerHTML]="sliceData.title"></h1>
                <a href="https://support.swype.ch/hc/{{currentLang}}">
                    <button class="button big headless bottomless">
                        <img src="/assets/icons/eye_white.svg" width="22" height="16" alt="">
                        <span [innerHTML]="sliceData.buttonText"></span>
                    </button>
                </a>
            </div>
        </div>
    </div>
</div>