import { Component, HostListener, Input, OnInit } from '@angular/core';
import { LanguageService } from '../language.service';
import {
  trigger,
  state,
  transition,
  style,
  animate
} from '@angular/animations';
import { StateService } from '../state.service';
import { AmplifyService } from '../amplify/amplify.service';
import { CreateUserBody, WebAppService } from '../api-client';
import { ActivatedRoute, Router } from '@angular/router';
import { TrackingService } from '../tracking.service';

@Component({
  selector: 'app-site-header',
  templateUrl: './site-header.component.html',
  styleUrls: ['./site-header.component.scss'],
  animations: [
    trigger('menu', [
      transition(':enter', [
        style({ transform: 'translateX(100vw)' }),
        animate('250ms ease-in', style({ transform: 'translateX(0)' }))
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0vw)' }),
        animate('250ms ease-in', style({ transform: 'translateX(100vw)' }))
      ])
    ]),
    trigger('logo', [
      state('shown', style({ opacity: 1 })),
      state('hidden', style({ opacity: 0 })),
      transition('* => *', animate('0.5s ease'))
    ]),
    trigger('hotline', [
      state('shown', style({ opacity: 1 })),
      state('hidden', style({ opacity: 0 })),
      transition('hidden => shown', animate('0.5s ease'))
    ])
  ]
})
export class SiteHeaderComponent implements OnInit {
  @Input() showCallUs: boolean;
  @Input() hotlineColor: 'default' | 'white' | 'black';
  user: string;
  loggedIn: boolean;
  alreadyOrdered: boolean;
  forgotPassword: boolean = false;
  hasPinkBackground: boolean = false;
  hasBlackBackground: boolean = true;
  signUpPage: boolean = false;
  signInPage: boolean = false;
  orderPage: boolean = false;
  landingPage: boolean = true;
  isMobileDevice: boolean = false;
  dashboardPage: boolean = false;
  isPageScrolling: boolean = false;
  supportLink: string = '';

  @HostListener('window:scroll', [])
  onScroll(): void {
    if (window.scrollY > 200) {
      this.isPageScrolling = true;
    } else {
      this.isPageScrolling = false;
    }
  }

  createSupportLink() {
    if (window.location.pathname === '/en') {
      this.supportLink = `https://support.swype.ch/hc/en-gb`;
      return;
    }
    this.supportLink = `https://support.swype.ch/hc${window.location.pathname}`;
  }

  async ngOnInit() {
    this.createSupportLink();
    await this.getCurrentUser();

    if (['/en', '/fr', '/de', '/it'].includes(window.location.pathname)) {
      this.hasBlackBackground = true;
    }

    if (window.location.pathname.includes('/esim')) {
      this.hasPinkBackground = true;
      this.hasBlackBackground = true;
      this.landingPage = false;
    }

    if (window.location.pathname.includes('/sign-up')) {
      this.signUpPage = true;
      this.hasBlackBackground = false;
      this.landingPage = false;
    }

    if (window.location.pathname.includes('/sign-in')) {
      this.signInPage = true;
      this.hasBlackBackground = false;
      this.landingPage = false;
    }

    if (window.location.pathname.includes('/order')) {
      this.orderPage = true;
      this.hasBlackBackground = false;
      this.landingPage = false;
    }

    if (window.location.pathname.includes('/dashboard')) {
      this.hasBlackBackground = true;
      this.landingPage = false;
      this.dashboardPage = true;
    }

    if (window.location.pathname.includes('/forgot-password')) {
      this.hasBlackBackground = false;
      this.forgotPassword = true;
      this.landingPage = false;
    }

    if (
      window.location.pathname.includes('/check-your-email') ||
      window.location.pathname.includes('/email-verification') ||
      window.location.pathname.includes('/renew-password')
    ) {
      this.hasBlackBackground = false;
      this.landingPage = false;
    }

    const checkForMobileDevice = () => {
      if (window.screen.width < 700) {
        this.isMobileDevice = true;
      }
    };

    checkForMobileDevice();

    window.onresize = checkForMobileDevice;
  }

  constructor(
    public lang: LanguageService,
    public state: StateService,
    private amplifyService: AmplifyService,
    private webAppService: WebAppService,
    private router: Router,
    private route: ActivatedRoute,
    private tracking: TrackingService
  ) {}

  toggleMenu() {
    this.state.menuActive = !this.state.menuActive;
  }

  goToDashboard() {
    const lang = this.route.snapshot.params['lang'];
    this.router.navigate(['/' + lang + '/dashboard']);
  }

  goToOrder() {
    const lang = this.route.snapshot.params['lang'];
    this.router.navigate(['/' + lang + '/order']);
  }

  goToLang(lang) {
    const currentLanguage = window.location.href
      .replace('http://', '')
      .replace('https://', '')
      .split('/')[1];
    const correctUrl = window.location.href.replace(
      '/' + currentLanguage,
      '/' + lang
    );
    window.location.href = correctUrl;
  }

  async getCurrentUser() {
    const user = await this.amplifyService.getCurrentUser();
    if (user) {
      this.user = user.attributes.email;
      this.loggedIn = this.user && !!this.user.length;

      (
        await this.webAppService.webUsersCognitoIdGet(user.attributes.sub)
      ).subscribe(
        (data: any) => {
          this.user = data.data.name.split(' ')[0];
          this.alreadyOrdered = data.data.address1 && data.data.address1.length;
        },
        async error => {
          console.log(error);
          console.log(error.status);
          if (error.status === 404) {
            await this.createUser(user);
            window.location.reload();
          }
        }
      );
    }
  }

  async createUser(user) {
    return new Promise(async (resolve, reject) => {
      (
        await this.webAppService.webUsersPut({
          id: user.attributes.sub,
          languageKillbill: this.getLanguage(),
          email: user.attributes.email,
          domain: user.loginMethod
        })
      ).subscribe(
        data => {
          this.tracking.ctaClick('website user registered');
          resolve(data);
        },
        error => {
          console.log(error);
          reject(error);
        }
      );
    });
  }

  getLanguage() {
    switch (this.route.snapshot.params['lang']) {
      case 'en':
        return 'en_US';
        break;
      case 'de':
        return 'de_CH';
        break;
      case 'it':
        return 'it_CH';
        break;
      case 'fr':
        return 'fr_CH';
        break;
      default:
        return 'en_US';
    }
  }

  async signOut() {
    await this.amplifyService.signOut();
    const lang = this.route.snapshot.params['lang'];
    this.router.navigate(['/' + lang + '/sign-in']);
  }

  goToSignUp() {
    const lang = this.route.snapshot.params['lang'];
    this.router.navigate(['/' + lang + '/sign-up']);
  }

  goToSignIn() {
    const lang = this.route.snapshot.params['lang'];
    this.router.navigate(['/' + lang + '/sign-in']);
  }
}
