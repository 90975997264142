import { Component, OnInit, Input } from '@angular/core';
import { ImageWithCtaSlice } from '../image-with-cta-slice';
import { LanguageService } from '../language.service';
import { TrackingService } from '../tracking.service';

@Component({
  selector: 'app-image-with-cta',
  templateUrl: './image-with-cta.component.html',
  styleUrls: ['./image-with-cta.component.scss']
})
export class ImageWithCTAComponent implements OnInit {
  @Input() sliceData: ImageWithCtaSlice;

  constructor(
    private tracking: TrackingService,
    public lang: LanguageService
  ) {}

  ngOnInit() {}

  openAppStore(store: 'google' | 'apple') {
    this.tracking.ctaClick('download link ' + store);
    setTimeout(() => {
      let link = this.tracking.getDownloadLinkForStore(store);
      window.open(link, '_blank');
    }, 250);
  }
}
