<div class="page-wrapper">
  <div class="page-content">
    <app-site-header></app-site-header>
    <notifier-container></notifier-container>
    <div class="container-fluid">
      <div class="title">
        <div class="main-title">{{ "RENEW_PASSWORD" | translate }}</div>
        <div class="subtitle">{{ "PICK_AND_SET_PASSWORD" | translate }}!</div>
      </div>
      <div class="form-container">
        <form [formGroup]="myForm">
          <text-field formControlName="password" (keyup)="onInput($event)" [value]="value"
            (change)="value = $event.target.value" [disabled]=false
            [placeholder]="'ENTER_YOUR_NEW_PASSWORD' | translate" type="password"
            [labelText]="'CHOOSE_NEW_PASSWORD' | translate">
          </text-field>
          <text-field formControlName="passwordConfirm" (keyup)="onInputConfirmation($event)" [value]="value"
            (change)="value = $event.target.value" [disabled]=false [placeholder]="'ENTER_CONFIRMATION' | translate"
            type="password" [labelText]="'CONFIRM_PASSWORD' | translate" (keyup.enter)="submit($event)">
          </text-field>
        </form>
        <!-- <img src="assets/icons/password-requirements.svg" alt="" srcset=""> -->
        <div class="official-requirements-container">
          <div class="requirements-title">{{ "OFFICIAL_REQUIREMENTS" | translate }}:
          </div>
          <ul>
            <li [ngClass]="{'inactive': !minimumEightCharacters}">{{ "MINIMUM_EIGHT_CHARACTERS" | translate }}</li>
            <li [ngClass]="{'inactive': !oneLowerCaseLetter}">{{ "ONE_LOWER_CASE_LETTER" | translate }}</li>
            <li [ngClass]="{'inactive': !oneUpperCaseLetter}">{{ "ONE_UPPER_CASE_LETTER" | translate }}</li>
            <li [ngClass]="{'inactive': !atLeastOneDigit}">{{ "ONE_DIGIT" | translate }}</li>
            <li [ngClass]="{'inactive': !oneSpecialSymbol}">{{ "ONE_SPECIAL_SYMBOL" | translate }}</li>
            <li [ngClass]="{'inactive': !confirmationMatchesPassword}">{{ "CONFIRMATION_MATCHES_PASSWORD" | translate}}
            </li>
          </ul>
        </div>
        <button class="sign-in-button" (click)="submit($event)" [ngClass]="{ 'active': valid }">
          {{ "RESET_PASSWORD" | translate}}
        </button>

        <button (click)="returnToSignIn()" class="forgot-password">{{ "RETURN_TO_SIGN_IN" | translate }}?
          <img class="arrow" src="assets/icons/return-to-sign-in-arrow.svg" alt="forgot password arrow" />
        </button>
      </div>

    </div>
  </div>
  <app-help-button></app-help-button>
  <div class="page no-gradient">
    <app-site-footer></app-site-footer>
  </div>
</div>
<spinner-overlay [showspinner]=loading></spinner-overlay>