<div class="container subscription-container">
  <h2 class="subscription-title">{{ 'YOUR_SUBSCRIPTIONS' | translate }}</h2>
  <ng-container *ngFor="let subscription of userSubscriptions; let last = last">
    <div class="line short"></div>
    <div class="subscription-item">
      <div class="subscription-number" (click)="subscription.showDetails = !subscription.showDetails">
        <img [src]="
            subscription.showDetails
              ? '/assets/icons/grey_arrow_down.svg'
              : '/assets/icons/grey_arrow_right.svg'
          " alt="" class="icon" />{{ subscription.number }}
      </div>

      <div class="subscription-details" *ngIf="subscription.showDetails">
        <ng-container *ngIf="!subscription.blockedSim; else simBlockedDetails">
          <span class="info">{{ 'MANAGE_SUBSCRIPTIONS' | translate }}</span>
          <ul class="subscription-status">
            <li>
              <span>{{ 'SIM_NUMBER' | translate | titlecase }}:</span>
              <span class="active">{{ 'ACTIVE' | translate | uppercase }}</span>
            </li>
            <li>
              <span>{{ 'SUBSCRIPTION' | translate | titlecase }}:</span>
              <span *ngIf="subscription.subscription == 'active'" class="active">{{ 'ACTIVE' | translate | uppercase
                }}</span>
              <span *ngIf="subscription.subscription == 'inactive'" class="inactive">{{ 'INACTIVE' | translate |
                uppercase }}</span>
            </li>
            <li>
              <span>5G:</span>
              <span *ngIf="subscription.fiveG == 'active'" class="active">{{
                'ACTIVE' | translate | uppercase
                }}</span>
              <span *ngIf="subscription.fiveG == 'inactive'" class="inactive">{{
                'INACTIVE' | translate | uppercase
                }}</span>
            </li>
          </ul>
          <button class="transparent-button" (click)="showWarning(subscription)">
            {{ 'BLOCK' | translate }}
          </button>
        </ng-container>

        <ng-template #simBlockedDetails>
          <ul class="subscription-status">
            <li>
              <span>{{ 'SIM_NUMBER' | translate | titlecase }}:</span>
              <span *ngIf="subscription.blockedSim !== 'notActivated'" class="blocked">{{
                'BLOCKED' | translate | uppercase
                }}</span>
              <span *ngIf="subscription.blockedSim === 'notActivated'" class="blocked">{{
                'INACTIVE' | translate | uppercase
                }}</span>
            </li>
            <li>
              <span>{{ 'SUBSCRIPTION' | translate | titlecase }}:</span>
              <span *ngIf="subscription.subscription == 'active'" class="active">{{ 'ACTIVE' | translate | uppercase
                }}</span>
              <span *ngIf="subscription.subscription == 'inactive'" class="inactive">{{ 'INACTIVE' | translate |
                uppercase }}</span>
            </li>
            <li>
              <span>5G:</span>
              <span *ngIf="subscription.fiveG == 'active'" class="active">{{
                'ACTIVE' | translate | uppercase
                }}</span>
              <span *ngIf="subscription.fiveG == 'inactive'" class="inactive">{{
                'INACTIVE' | translate | uppercase
                }}</span>
            </li>
          </ul>
          <button *ngIf="subscription.blockedSim !== 'notActivated'" class="transparent-button"
            (click)="showWarning(subscription)">
            {{ 'UNBLOCK' | translate }}
          </button>
        </ng-template>
      </div>
    </div>
    <div *ngIf="last" class="line short"></div>
  </ng-container>
  <div class="additional-subscriptions">
    <h3>{{ 'ADDITIONAL_SUBSCRIPTION' | translate }}</h3>
    <span class="info">{{ 'MORE_SUBSCRIPTIONS' | translate }}</span>
  </div>
</div>

<ng-container *ngIf="showWarningPopup">
  <app-block-sim *ngIf="typeOfWarning == 'block'" (close)="closeWarning($event)" (simBlocked)="changeSimStatus($event)">
  </app-block-sim>
  <app-unblock-sim *ngIf="typeOfWarning == 'activate'" (close)="closeWarning($event)"
    (simBlocked)="changeSimStatus($event)"></app-unblock-sim>
</ng-container>
