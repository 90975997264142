<div class="page-wrapper">
  <div class="page-content">
    <app-site-header></app-site-header>
    <notifier-container></notifier-container>
    <div class="container-fluid">
      <div class="title">
        <div class="main-title">{{ "FORGOT_YOUR_PASSWORD" | translate }}?</div>
        <div class="subtitle">{{ "TYPE_EMAIL_IN_A_FIELD_BELOW" | translate }}
        </div>
      </div>
      <div class="form-container">
        <form [formGroup]="myForm">
          <text-field formControlName="email" [value]="value" (keyup)="onEmailInput($event)" [disabled]=false
            [placeholder]="'you@example.com'" [labelText]="'EMAIL_ADDRESS' | translate" (keyup.enter)="onSubmit()">
          </text-field>
        </form>
        <a (click)="onSubmit()">
          <button class="sign-in-button" [ngClass]="{ 'active': valid }">{{ "SEND_CODE" | translate }}</button>
        </a>
      </div>


      <button (click)="returnToSignIn()" class="forgot-password">{{ "RETURN_TO_SIGN_IN" | translate }}
        <img class="arrow" src="assets/icons/return-to-sign-in-arrow.svg" alt="forgot password arrow" />
      </button>

    </div>
  </div>
  <app-help-button></app-help-button>
  <div class="page no-gradient">
    <app-site-footer></app-site-footer>
  </div>
</div>
<spinner-overlay [showspinner]=loading></spinner-overlay>