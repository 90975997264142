<div class="page-wrapper">
  <div class="page-content">
    <app-site-header></app-site-header>
    <notifier-container></notifier-container>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-5 d-none d-xl-block" align="center">
          <div class="hero-container">
            <!-- <img class="hero-background" src="assets/icons/swype-hero-bg.png" alt="background"> -->
            <img
              class="hero-card"
              src="assets/icons/swype-hero-card2.png"
              alt="background"
            />
            <div class="hero-text">{{ "ORDER_FREE_SIM_CARD" | translate }}</div>
          </div>
        </div>

        <div class="col-md-7">
          <form class="form-container">
            <div class="hero-title-mobile">
              {{ "ORDER_FREE_SIM_CARD" | translate }}
            </div>
            <div class="second-column-container">
              <div class="row">
                <div class="col-md-12 subscription-step-label">
                  {{ "PERSONAL_INFORMATION" | translate }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <text-field
                    (keyup)="onFieldUpdate($event, 'firstName')"
                    required="true"
                    [labelText]="'FIRST_NAME' | translate"
                  >
                  </text-field>
                </div>
                <div class="col-md-6">
                  <text-field
                    (keyup)="onFieldUpdate($event, 'lastName')"
                    required="true"
                    [labelText]="'LAST_NAME' | translate"
                  >
                  </text-field>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <label class="required">
                    {{ "CITY" | translate }}<span class="required"> *</span>
                    <ng-select
                      notFoundText="{{ 'NO_ITEMS_FOUND' | translate }}"
                      class="custom"
                      (clear)="onClear('town')"
                      name="town"
                      (keyup)="autocompleteChange('town', $event.target.value)"
                      (change)="autocompleteSelect('town', $event)"
                      [(ngModel)]="model.town"
                      bindLabel="town"
                    >
                      <ng-option
                        *ngFor="let option of autocompleteResults"
                        [value]="option"
                        >{{ option.label }}
                      </ng-option>
                    </ng-select>
                  </label>
                </div>
                <div class="col-md-6">
                  <label class="required">
                    {{ "PLZ" | translate }}<span class="required"> *</span>
                    <ng-select
                      notFoundText="{{ 'NO_ITEMS_FOUND' | translate }}"
                      class="custom"
                      (clear)="onClear('plz')"
                      name="plz"
                      (keyup)="autocompleteChange('plz', $event.target.value)"
                      (change)="autocompleteSelect('plz', $event)"
                      [(ngModel)]="model.plz"
                      bindLabel="plz"
                    >
                      <ng-option
                        *ngFor="let option of autocompleteResults"
                        [value]="option"
                        >{{ option.label }}
                      </ng-option>
                    </ng-select>
                  </label>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <label class="required">
                    {{ "STREET" | translate }}<span class="required"> *</span>
                    <ng-select
                      notFoundText="{{ 'NO_ITEMS_FOUND' | translate }}"
                      class="custom"
                      (clear)="onClear('street')"
                      name="street"
                      (keyup)="
                        autocompleteChange('street', $event.target.value)
                      "
                      (change)="autocompleteSelect('street', $event)"
                      [(ngModel)]="model.street"
                      bindLabel="street"
                    >
                      <ng-option
                        *ngFor="let option of autocompleteResults"
                        [value]="option"
                        >{{ option.label }}
                      </ng-option>
                    </ng-select>
                  </label>
                </div>
                <div class="col-md-6">
                  <label class="required">
                    {{ "NUMBER" | translate }}<span class="required"> *</span>
                    <ng-select
                      notFoundText="{{ 'NO_ITEMS_FOUND' | translate }}"
                      class="custom"
                      (clear)="onClear('number')"
                      name="number"
                      (keyup)="
                        autocompleteChange('number', $event.target.value)
                      "
                      (change)="autocompleteSelect('number', $event)"
                      [(ngModel)]="model.number"
                      bindLabel="number"
                    >
                      <ng-option
                        *ngFor="let option of autocompleteResults"
                        [value]="option"
                        >{{ option.label }}
                      </ng-option>
                    </ng-select>
                  </label>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <text-field
                    (keyup)="onFieldUpdate($event, 'company')"
                    class="post-box-field"
                    labelClass="post-box-field"
                    [labelText]="'NAME_ON_POST_BOX' | translate"
                  >
                  </text-field>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="recaptcha">
                    <re-captcha
                      (resolved)="resolved($event)"
                      siteKey="6Lc1cGscAAAAAJnb2WIygkdLAhV818dDnujfuMsn"
                    >
                    </re-captcha>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="terms-and-conditions">
                    <input
                      (click)="updateAgreementStatus()"
                      id="terms-and-conditions"
                      type="checkbox"
                    />
                    <label for="terms-and-conditions">
                      {{ "I_AGREE" | translate }}
                      <a
                        class="link"
                        href="/assets/pdfs/{{ 'TERMSPDFNAME' | translate }}.pdf"
                        target="_blank"
                        >{{ "TERMS_AND_CONDITIONS" | translate }}</a
                      >
                      <span *ngIf="currentLanguage == 'de'">
                        {{ "I_AGREE_ENDING" | translate }}</span
                      >
                    </label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <img
                    src="assets/icons/order-separator.svg"
                    alt="separator"
                    class="order-separator"
                  />
                </div>
              </div>
              <div class="row justify-content-md-center">
                <div class="col-md-12">
                  <!-- (click)="submit($event)" -->
                  <button
                    (click)="submit($event, false)"
                    class="submit-button"
                    [ngClass]="{ active: valid }"
                  >
                    {{ "ORDER_NOW" | translate }}
                  </button>
                </div>
              </div>
              <div class="row justify-content-md-center">
                <div class="col-md-12">
                  <!-- (click)="submit($event)" -->
                  <button
                    (click)="submit($event, true)"
                    class="submit-button esim"
                    [ngClass]="{ active: valid }"
                  >
                    {{ "USE_ESIM" | translate }}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <app-help-button></app-help-button>
  <div class="page no-gradient">
    <app-site-footer></app-site-footer>
  </div>
</div>

<spinner-overlay [showspinner]="loading"></spinner-overlay>
