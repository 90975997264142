import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AmplifyService } from '../amplify/amplify.service';
import { WebAppService } from '../api-client';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../language.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  userData: any;
  onBoardingStep: string;
  loading: boolean = false;
  activeStep: number;
  userIsOnboarded: boolean | string = 'loading';
  userSubscriptions: {}[];
  simNumberToBlock: number;
  simNumberToUnblock: number;
  cognitoId: string;
  suspendedAccount: boolean = false;
  suspensionDate: string;
  currentLang: string;

  constructor(
    private webAppService: WebAppService,
    private amplifyService: AmplifyService,
    private router: Router,
    private route: ActivatedRoute,
    private notifier: NotifierService,
    private translate: TranslateService,
    public lang: LanguageService
  ) {
    this.onBoardingStep = '';
  }

  async blockSim(event) {
    this.loading = true;
    (
      await this.webAppService.webWebBlockUnblockSimCardPost({
        killbillId: event.id,
        internationalId: event.internationalId,
        status: 'BLOCK'
      })
    ).subscribe(async data => {
      this.notifier.notify('success', this.translate.instant('OK'));
      const interval = setInterval(async () => {
        await this.getUserSubscriptions();

        this.userSubscriptions.map((subscription: any) => {
          if (subscription.number === event.number) {
            if (subscription.blockedSim) {
              this.loading = false;
              clearInterval(interval);
              window.location.reload();
            }
          }
        });
      }, 5000);
    });
  }

  async unblockSim(event) {
    this.loading = true;
    (
      await this.webAppService.webWebBlockUnblockSimCardPost({
        killbillId: event.id,
        internationalId: event.internationalId,
        status: 'UNBLOCK'
      })
    ).subscribe(async data => {
      this.notifier.notify('success', this.translate.instant('OK'));
      const interval = setInterval(async () => {
        await this.getUserSubscriptions();

        this.userSubscriptions.map((subscription: any) => {
          if (subscription.number === event.number) {
            if (!subscription.blockedSim) {
              this.loading = false;
              clearInterval(interval);
              window.location.reload();
            }
          }
        });
      }, 5000);
    });
  }

  setActiveStep(step: string) {
    switch (step) {
      case 'OFFER': {
        this.activeStep = 1;
        break;
      }
      case 'KYC_DOCUMENT_SELECTOR': {
        this.activeStep = 2;
        break;
      }
      case 'CREDIT_CARD': {
        this.activeStep = 3;
        break;
      }
      case 'USER_CHOICE':
      case 'SCAN_QR_CODE': {
        this.activeStep = 4;
        break;
      }
      case 'ACTIVATE_SUBSCRIPTION': {
        this.activeStep = 5;
        break;
      }
      default: {
        this.activeStep = 0;
        break;
      }
    }
  }

  async getUserData() {
    this.loading = true;
    const user = await this.amplifyService.getCurrentUser();

    if (!user) {
      const lang = this.route.snapshot.params['lang'];
      this.router.navigate(['/' + lang + '/sign-in']);
    }

    this.cognitoId = user.username;

    (
      await this.webAppService.webUsersCognitoIdGet(user.attributes.sub)
    ).subscribe(
      (data: any) => {
        this.userData = data;

        if (this.userData.data.gdpr_request_time) {
          this.suspensionDate = this.userData.data.gdpr_request_time;
          this.suspendedAccount = true;
          this.loading = false;
          return;
        }
        this.onBoardingStep = data.data.__ON_BOARDING_STEP;

        this.setActiveStep(data.data.__ON_BOARDING_STEP);
        if (data.data.__ON_BOARDING_STEP === 'ON_BOARDED') {
          this.userIsOnboarded = true;
        } else {
          this.userIsOnboarded = false;
        }
        this.loading = false;
      },
      async error => {
        console.log(error);
        console.log(error.status);
      }
    );
  }

  numberWithSpaces(x) {
    const n = x.toString();
    return (
      n[0] +
      n[1] +
      n[2] +
      ' ' +
      n[3] +
      n[4] +
      n[5] +
      ' ' +
      n[6] +
      n[7] +
      ' ' +
      n[8] +
      n[9]
    );
  }

  async getUserSubscriptions() {
    (
      await this.webAppService.webWebGetChildAccountsGet(this.cognitoId, true)
    ).subscribe(data => {
      // @ts-ignore
      this.userSubscriptions = data.data.data
        .map(account => {
          if (!account.name) {
            return false;
          }

          if (account.name === 'phone number') {
            return false;
          }

          let blockedSim: Boolean | string = false;

          if (account.blockedSim === 'active') {
            blockedSim = true;
          }

          if (
            account.numberNotYetActivated &&
            account.numberNotYetActivated === 'true'
          ) {
            blockedSim = 'notActivated';
          }

          if (account.portInStatus && account.portInStatus !== 'COMPLETE') {
            return false;
          }

          function isSubscriptionActive(account) {
            return [
              'subscriptionActiveUntil',
              'talkActiveUntil',
              'internationalActiveUntil'
            ]
              .map(subscriptionName => {
                return account[subscriptionName] &&
                  new Date(account[subscriptionName]) > new Date()
                  ? 'active'
                  : 'inactive';
              })
              .filter(status => status === 'active').length;
          }

          return {
            number: this.numberWithSpaces(account.name),
            status: 'active',
            subscription: isSubscriptionActive(account) ? 'active' : 'inactive',
            fiveG:
              account.subscriptions.filter(
                subscription => subscription.planName === 'swype-5g'
              ).length ||
              (account.fiveGActiveUntil &&
                new Date(account.fiveGActiveUntil) > new Date())
                ? 'active'
                : 'inactive',
            showDetails: false,
            id: account.accountId,
            internationalId: account.internationalAccountId,
            blockedSim: blockedSim
          };
        })
        .filter(object => !!object);
    });
  }

  async ngOnInit(): Promise<void> {
    this.lang = this.route.snapshot.params['lang'];
    this.loading = true;
    await this.getUserData();
    await this.getUserSubscriptions();

    this.amplifyService.getCurrentUser().then(data => {
      if (!data) {
        this.notifier.notify(
          'error',
          this.translate.instant('PASSWORD_PROCESS_RESET_NOT_STARTED')
        );
        setTimeout(() => {
          const lang = this.route.snapshot.params['lang'];
          this.router.navigate(['/' + lang + '/sign-in']);
        }, 1000);
      }
    });
  }
}
