import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TrackingService } from '../tracking.service';

@Component({
  selector: 'app-user-subscriptions',
  templateUrl: './user-subscriptions.component.html',
  styleUrls: ['./user-subscriptions.component.scss']
})
export class UserSubscriptionsComponent implements OnInit {
  showWarningPopup: boolean = false;
  typeOfWarning: 'block' | 'activate';
  subscriptionToChange: number;

  @Output()
  simNumberToBlock = new EventEmitter<number>();

  @Output()
  simNumberToUnblock = new EventEmitter<number>();

  @Input()
  userSubscriptions;

  constructor(
    private tracking: TrackingService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  goToOrder() {
    this.tracking.ctaClick('website order started');
    const lang = this.route.snapshot.params['lang'];
    this.router.navigate(['/' + lang + '/order']);
  }

  changeSimStatus(event: boolean) {
    if (!event) {
      this.simNumberToUnblock.emit(this.subscriptionToChange);
      return;
    }
    this.simNumberToBlock.emit(this.subscriptionToChange);
  }

  showWarning(subscription) {
    this.showWarningPopup = true;
    this.subscriptionToChange = subscription;
    if (subscription.blockedSim) {
      this.typeOfWarning = 'activate';
      return;
    }
    this.typeOfWarning = 'block';
  }

  closeWarning(close: Boolean) {
    if (close === true) {
      this.showWarningPopup = false;
    }
  }

  ngOnInit(): void {}
}
