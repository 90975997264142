<div class="container dashboard-container">
  <div class="subtitle">
    {{ 'PLEASE_COMPLETE_THE_PROCESS_IN_THE_APP' | translate }}
  </div>
  <div class="status">
    {{ 'ONBOARDING_STATUS' | translate }}:
    <span class="bold">{{ onBoardingStep | translate }}</span>
  </div>

  <div class="row visible-only-on-mobile">
    <div class="sum-up-title">
      {{ 'DOWNLOAD_THE_APP_HERE' | translate }}
    </div>
    <div class="sum-up-subtitle">
      {{ 'FIRST_MOBILE_CONTRACT' | translate }}
    </div>

    <div class="store-buttons-container">
      <img
        (click)="openAppStore('apple')"
        src="../../assets/icons/app-store-badge-{{ lang.current }}.svg"
        alt=""
        class="app-store"
      />
      <img
        (click)="openAppStore('google')"
        src="../../assets/icons/google-play-badge-{{ lang.current }}.png"
        alt=""
        class="google-play"
      />
    </div>
  </div>

  <!-- <button (click)="goToOrder()" *ngIf="onBoardingStep == 'OFFER'" class="order-button">
  {{ "ORDER_A_SIM" | translate}}!
</button> -->
  <div *ngIf="activeStep > 1" class="line"></div>

  <ng-container *ngFor="let step of steps; let i = index; let last = last">
    <div class="row">
      <div class="item-container" [ngClass]="{ red: i + 1 == activeStep }">
        <img
          [src]="
            i + 1 == activeStep
              ? '/assets/icons/step-white' + (i + 1) + '.svg'
              : '/assets/icons/step' + (i + 1) + '.svg'
          "
          alt=""
          class="icon"
        />
        <div class="item-description">
          <div class="title">{{ step.title | translate }}</div>
          <ng-container
            *ngIf="i + 1 >= activeStep; then subtitle; else done"
          ></ng-container>
          <ng-template #subtitle>
            <div class="subtitle">
              {{ step.subtitle | translate }}
            </div>
          </ng-template>
          <ng-template #done>
            <div class="subtitle">
              {{ step.done_subtitle | translate }}
            </div>
          </ng-template>
        </div>
        <img
          *ngIf="i + 1 < activeStep"
          src="/assets/icons/check-green.svg"
          alt=""
          class="icon check"
        />
      </div>
      <div *ngIf="!last" class="line short"></div>
    </div>
  </ng-container>

  <div class="row not-visible-on-mobile">
    <div class="information-about-sim">
      {{ 'NEED_ANOTHER_SIM' | translate }}
    </div>
    <div class="sum-up-title">
      {{ 'DOWNLOAD_THE_APP_HERE' | translate }}
    </div>
    <div class="sum-up-subtitle">
      {{ 'FIRST_MOBILE_CONTRACT' | translate }}
    </div>

    <div class="store-buttons-container">
      <img
        (click)="openAppStore('apple')"
        src="../../assets/icons/app-store-badge-{{ lang.current }}.svg"
        alt=""
        class="app-store"
      />
      <img
        (click)="openAppStore('google')"
        src="../../assets/icons/google-play-badge-{{ lang.current }}.png"
        alt=""
        class="google-play"
      />
    </div>
  </div>
</div>
