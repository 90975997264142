import { Component, OnInit } from '@angular/core';
import { AmplifyService } from '../amplify/amplify.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'social-login',
  templateUrl: './social-login.component.html',
  styleUrls: ['./social-login.component.scss']
})
export class SocialLoginComponent implements OnInit {
  constructor(
    private amplifyService: AmplifyService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {}

  async loginUsingFacebook(event) {
    const result = await this.amplifyService.signInFacebook();

    const lang = this.route.snapshot.params['lang'];
    this.router.navigate(['/' + lang + '/dashboard']);
  }

  async loginUsingGoogle(event) {
    const result = await this.amplifyService.signInGoogle();

    const lang = this.route.snapshot.params['lang'];
    this.router.navigate(['/' + lang + '/dashboard']);
  }
}
