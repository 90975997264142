import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import { AmplifyService } from '../amplify/amplify.service';
import { WebAppService } from '../api-client';
import { EmailService } from '../email.service';
import { TrackingService } from '../tracking.service';

@Component({
  selector: 'sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {
  valid: boolean;
  email: string = '';
  password: string = '';
  minimumEightCharacters: boolean;
  oneLowerCaseLetter: boolean;
  oneUpperCaseLetter: boolean;
  atLeastOneDigit: boolean;
  oneSpecialSymbol: boolean;
  confirmationMatchesPassword: boolean;
  loading: boolean = false;

  constructor(
    private webAppService: WebAppService,
    private amplifyService: AmplifyService,
    private router: Router,
    private notifier: NotifierService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private tracking: TrackingService,
    private emailService: EmailService
  ) {
    this.valid = false;
    this.minimumEightCharacters = false;
    this.oneLowerCaseLetter = false;
    this.oneUpperCaseLetter = false;
    this.atLeastOneDigit = false;
    this.oneSpecialSymbol = false;
  }

  validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  onPaste(event: ClipboardEvent, field) {
    this[field] = event.clipboardData.getData('text');
    this.checkValidity();
  }

  checkValidity() {
    if (
      this.minimumEightCharacters &&
      this.oneLowerCaseLetter &&
      this.oneUpperCaseLetter &&
      this.atLeastOneDigit &&
      this.oneSpecialSymbol
    ) {
      this.valid = this.validateEmail(this.email);
    } else {
      this.valid = false;
    }
  }

  onEmailInput(event) {
    this.email = event.target.value;
    this.checkValidity();
  }

  hasLowerCase(str) {
    return /[a-z]/.test(str);
  }

  hasUpperCase(str) {
    return /[A-Z]/.test(str);
  }

  hasAtLeastOneDigit(str) {
    return /\d/.test(str);
  }

  hasSymbol(str) {
    return /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(str);
  }

  onPasswordInput(event) {
    const value = event.target.value;
    this.minimumEightCharacters = value.length > 7;
    this.oneLowerCaseLetter = this.hasLowerCase(value);
    this.oneUpperCaseLetter = this.hasUpperCase(value);
    this.atLeastOneDigit = this.hasAtLeastOneDigit(value);
    this.oneSpecialSymbol = this.hasSymbol(value);
    this.password = value;
    this.checkValidity();
  }

  async signUp(event) {
    if (!this.valid) {
      return;
    }
    this.loading = true;
    const user = await this.amplifyService.signUp(
      this.email,
      this.password,
      this.email,
      null
    );
    if (user.code === 'UsernameExistsException') {
      this.loading = false;
      this.notifier.notify('error', this.translate.instant('EMAIL_IN_USE'));
      return;
    }
    if (!user) {
      this.loading = false;
      this.notifier.notify(
        'error',
        this.translate.instant('INCORRECT_EMAIL_OR_PASSWORD')
      );
      return;
    }
    this.loading = false;
    const lang = this.route.snapshot.params['lang'];
    this.emailService.sendEmail(this.email);
    this.router.navigate(['/' + lang + '/email-verification/']);
  }

  ngOnInit(): void {
    this.tracking.ctaClick('website register form viewed');
  }
}
