<div class="page-wrapper">
  <div class="page-content">
    <app-site-header></app-site-header>
    <notifier-container></notifier-container>
    <div class="container signup-container">
      <div class="title">
        <div class="main-title">{{ 'SIGN_UP' | translate }}</div>
        <div class="subtitle">{{ 'ENTER_DETAILS' | translate }}</div>
      </div>
      <social-login></social-login>
      <div class="form-container">
        <text-field
          [authentication]="true"
          [disabled]="false"
          (paste)="onPaste($event, 'email')"
          (keyup)="onEmailInput($event)"
          [placeholder]="'you@example.com'"
          [labelText]="'EMAIL_ADDRESS' | translate"
        >
        </text-field>
        <text-field
          [authentication]="true"
          type="password"
          (paste)="onPaste($event, 'password')"
          (keyup)="onPasswordInput($event)"
          [disabled]="false"
          [placeholder]="'ENTER_YOUR_PASSWORD' | translate"
          [labelText]="'PASSWORD' | translate"
          (keyup.enter)="signUp($event)"
        >
        </text-field>
        <div class="official-requirements-container">
          <div class="requirements-title">
            {{ 'OFFICIAL_REQUIREMENTS' | translate }}:
          </div>
          <ul>
            <li [ngClass]="{ inactive: !minimumEightCharacters }">
              {{ 'MINIMUM_EIGHT_CHARACTERS' | translate }}
            </li>
            <li [ngClass]="{ inactive: !oneLowerCaseLetter }">
              {{ 'ONE_LOWER_CASE_LETTER' | translate }}
            </li>
            <li [ngClass]="{ inactive: !oneUpperCaseLetter }">
              {{ 'ONE_UPPER_CASE_LETTER' | translate }}
            </li>
            <li [ngClass]="{ inactive: !atLeastOneDigit }">
              {{ 'ONE_DIGIT' | translate }}
            </li>
            <li [ngClass]="{ inactive: !oneSpecialSymbol }">
              {{ 'ONE_SPECIAL_SYMBOL' | translate }}
            </li>
          </ul>
        </div>
        <a (click)="signUp($event)">
          <button class="sign-in-button" [ngClass]="{ active: valid }">
            {{ 'SIGN_UP' | translate }}
          </button>
        </a>
      </div>
    </div>
  </div>
  <app-help-button></app-help-button>
  <div class="page no-gradient">
    <app-site-footer></app-site-footer>
  </div>
</div>
<spinner-overlay [showspinner]="loading"></spinner-overlay>
