<div
  class="site-header"
  [ngClass]="{
    white: !hasBlackBackground,
    'linear-gradient': hasBlackBackground
  }"
>
  <div class="header-container">
    <div class="left-flex-container">
      <h1
        class="logo-container"
        [@logo]="state.menuActive ? 'hidden' : 'shown'"
      >
        <a routerLink="/{{ lang.current }}">
          <img
            [src]="
              hasBlackBackground
                ? '/assets/swype_logo_white.png'
                : '/assets/swype_logo_red.png'
            "
            class="logo"
            alt="swype"
          />
        </a>
      </h1>
      <!--
      <ng-container *ngTemplateOutlet="!isMobileDevice && landingPage ? hotline : '' ">
      </ng-container>
      -->
    </div>
    <div
      class="menu-toggle-container"
      (click)="toggleMenu()"
      [ngClass]="{ fix: state.menuActive }"
    >
      <div class="menu-toggle" [ngClass]="{ open: state.menuActive }">
        <span></span>
        <span></span>
      </div>
    </div>
    <div *ngIf="!orderPage" class="right-flex-container">
      <a
        href="https://support.swype.ch/hc/{{
          lang.current
        }}/articles/360003862298-How-can-I-activate-my-SIM-card-"
        *ngIf="landingPage && isPageScrolling"
        class="dont-have-an-account pointer"
        [ngClass]="{ dark: !hasBlackBackground }"
      >
        {{ "ACTIVATE_SIM" | translate }}
      </a>
      <div *ngIf="!isPageScrolling" class="language-select-container">
        <select
          (change)="goToLang($event.target.value)"
          class="language-select"
          name="language-selector"
          [ngClass]="{
            dark: !hasBlackBackground
          }"
          id="language-selector"
        >
          <option value="en" [selected]="this.lang.current === 'en'">{{
            "EN" | translate
          }}</option>
          <option value="de" [selected]="this.lang.current === 'de'">{{
            "DE" | translate
          }}</option>
          <option value="it" [selected]="this.lang.current === 'it'">{{
            "IT" | translate
          }}</option>
          <option value="fr" [selected]="this.lang.current === 'fr'">{{
            "FR" | translate
          }}</option>
        </select>
      </div>
      <div
        *ngIf="!loggedIn && !signUpPage && !signInPage && !landingPage"
        class="dont-have-an-account pointer"
        [ngClass]="{ dark: !hasBlackBackground }"
      >
        <a (click)="goToSignUp()">{{ "DONT_HAVE_AN_ACCOUNT" | translate }}?</a>
      </div>
      <!-- <div *ngIf="hasBlackBackground" class="separation-line"></div> -->
      <div
        *ngIf="loggedIn && !alreadyOrdered"
        class="dont-have-an-account"
        [ngClass]="{ dark: !hasBlackBackground }"
      >
        <img class="user-icon" src="/assets/icons/user_icon.svg" alt="" />
        {{ "WELCOME_BACK" | translate }} {{ user }}!
      </div>
      <div
        *ngIf="loggedIn && alreadyOrdered"
        class="dont-have-an-account"
        [ngClass]="{ dark: !hasBlackBackground }"
      >
        {{ "HELLO" | translate }} {{ user }}!
      </div>
      <a
        href="{{ supportLink }}"
        *ngIf="landingPage"
        class="dont-have-an-account pointer"
        [ngClass]="{ dark: !hasBlackBackground }"
      >
        {{ "GET_SUPPORT" | translate }}
      </a>
      <a
        *ngIf="loggedIn && !alreadyOrdered && !orderPage && !landingPage"
        (click)="goToOrder()"
      >
        <button class="sign-in-button pink">
          {{ "ORDER_A_SIM" | translate }}
        </button>
      </a>
      <a
        *ngIf="!loggedIn && !signInPage && !forgotPassword"
        (click)="goToSignIn()"
      >
        <button
          class="sign-in-button"
          [ngClass]="{ 'text-dark': !hasBlackBackground }"
        >
          {{ "SIGN_IN" | translate }}
        </button>
      </a>
      <a
        class=""
        *ngIf="!loggedIn && !alreadyOrdered && hasBlackBackground"
        (click)="goToOrder()"
      >
        <button class="sign-in-button pink">
          <img class="sim-icon" src="/assets/icons/icon_SIM.svg" alt="" />
          {{ "TRY_FOR_FREE" | translate }}
        </button>
      </a>
      <a
        *ngIf="forgotPassword || (!loggedIn && signInPage && !signUpPage)"
        (click)="goToSignUp()"
      >
        <button class="sign-in-button empty-pink">
          {{ "SIGN_UP_FREE" | translate }}
        </button>
      </a>
      <a
        *ngIf="loggedIn && alreadyOrdered && !dashboardPage"
        (click)="goToDashboard()"
      >
        <button
          class="sign-in-button pink"
          [ngClass]="{ 'text-dark': !hasBlackBackground }"
        >
          {{ "GO_TO_DASHBOARD" | translate }}
          <img class="icon-arrow" src="/assets/icons/icon_arrow.svg" alt="" />
        </button>
      </a>
      <a
        *ngIf="loggedIn && (dashboardPage || landingPage)"
        routerLink="/{{ lang.current }}/sign-in"
        class="link"
        [ngClass]="{
          dark: !hasBlackBackground
        }"
        (click)="signOut()"
        >{{ "SIGN_OUT_BTN" | translate }}</a
      >
    </div>
  </div>

  <div *ngIf="state.menuActive" class="menu">
    <div class="menu-items">
      <a
        href="https://support.swype.ch/hc/{{
          lang.current
        }}/articles/360003862298-How-can-I-activate-my-SIM-card-"
        *ngIf="landingPage"
        class="dont-have-an-account pointer"
        [ngClass]="{ dark: !hasBlackBackground }"
      >
        {{ "ACTIVATE_SIM" | translate }}
      </a>
      <!--
      <div *ngIf="!loggedIn && !signUpPage" class="dont-have-an-account pointer"
        [ngClass]="{ dark: !hasBlackBackground }">
        <a (click)="goToSignUp()">{{ 'DONT_HAVE_AN_ACCOUNT' | translate }}?</a>
      </div>
      -->
      <div
        *ngIf="loggedIn && alreadyOrdered"
        class="dont-have-an-account"
        [ngClass]="{ dark: !hasBlackBackground }"
      >
        {{ "HELLO" | translate }} {{ user }}!
      </div>
      <div
        *ngIf="loggedIn && !alreadyOrdered"
        class="dont-have-an-account"
        [ngClass]="{ dark: !hasBlackBackground }"
      >
        {{ "LOGGED_IN_AS" | translate }} {{ user }}!
      </div>

      <div class="btn-container">
        <a
          *ngIf="!loggedIn && !signInPage"
          routerLink="/{{ lang.current }}/sign-in"
          (click)="goToSignIn(); state.menuActive = false"
        >
          <button
            class="sign-in-button"
            [ngClass]="{ 'text-dark': !hasBlackBackground }"
          >
            {{ "SIGN_IN" | translate }}
          </button></a
        >
        <a
          *ngIf="!loggedIn && signInPage && !signUpPage"
          routerLink="/{{ lang.current }}/sign-up"
          (click)="goToSignUp(); state.menuActive = false"
        >
          <button class="sign-in-button empty-pink">
            {{ "SIGN_UP_FREE" | translate }}
          </button></a
        >
        <a
          class="btn pre-order-link"
          *ngIf="loggedIn && !alreadyOrdered && !orderPage"
          (click)="goToOrder()"
        >
          {{ "ORDER_A_SIM" | translate }}
        </a>
        <a
          *ngIf="loggedIn && alreadyOrdered && !dashboardPage"
          routerLink="/{{ lang.current }}/sign-in"
          class="btn pre-order-link"
          (click)="goToDashboard(); state.menuActive = false"
          >{{ "GO_TO_DASHBOARD" | translate }}
          <img class="icon-arrow" src="/assets/icons/icon_arrow.svg" alt=""
        /></a>

        <a
          *ngIf="loggedIn"
          routerLink="/{{ lang.current }}/sign-in"
          class="link"
          [ngClass]="{ dark: !hasBlackBackground }"
          (click)="signOut(); state.menuActive = false"
          >{{ "SIGN_OUT_BTN" | translate }}</a
        >
        <a
          href="{{ supportLink }}"
          class="dont-have-an-account pointer"
          [ngClass]="{ dark: !hasBlackBackground }"
        >
          {{ "GET_SUPPORT" | translate }}
        </a>
      </div>

      <!--
      <ng-container *ngTemplateOutlet="isMobileDevice && landingPage ? hotline : ''">
      </ng-container>
      -->

      <div class="language-select-container">
        <select
          (change)="goToLang($event.target.value)"
          class="language-select"
          [ngClass]="{
            dark: !hasBlackBackground
          }"
          name="mobile-language-selector"
          id="mobile-language-selector"
        >
          <option value="en" [selected]="this.lang.current === 'en'">{{
            "EN" | translate
          }}</option>
          <option value="de" [selected]="this.lang.current === 'de'">{{
            "DE" | translate
          }}</option>
          <option value="it" [selected]="this.lang.current === 'it'">{{
            "IT" | translate
          }}</option>
          <option value="fr" [selected]="this.lang.current === 'fr'">{{
            "FR" | translate
          }}</option>
        </select>
      </div>
    </div>
  </div>

  <div *ngIf="!hasBlackBackground" class="header-separator"></div>
</div>

<ng-template #hotline>
  <div
    *ngIf="hasBlackBackground || state.menuActive"
    class="separation-line"
  ></div>
  <div class="hotline-container">
    <div class="hotline-logo">
      <img
        [src]="
          hasBlackBackground
            ? '/assets/icons/phone_white.svg'
            : '/assets/icons/phone_gray.svg'
        "
        alt="phone icon"
      />
    </div>
    <div class="hotline-text">
      <p [ngClass]="{ dark: !hasBlackBackground }">
        {{ "HOTLINE" | translate }}
      </p>
      <p class="hotline-number">0800 00 48 50</p>
    </div>
  </div>
</ng-template>
