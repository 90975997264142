<div class="page-wrapper">

  <app-site-header [showCallUs]="showCallUs" [hotlineColor]="hotlineColor"></app-site-header>

  <div class="page main">
    <div class="page-content">

      <div *ngFor="let item of slices">
        <app-intro *ngIf="item.type == 'intro'" [sliceData]="item"></app-intro>
        <app-pricing *ngIf="item.type == 'pricing'" [sliceData]="item"></app-pricing>
        <app-esim *ngIf="item.type == 'esim'" [sliceData]="item"></app-esim>
        <!-- <app-banner *ngIf="item.type == 'banner'" [sliceData]="item"></app-banner> -->
        <app-feature *ngIf="item.type == 'features'" [sliceData]="item"></app-feature>
        <app-image-with-cta *ngIf="item.type == 'image_with_cta'" [sliceData]="item"></app-image-with-cta>
        <app-order-slice *ngIf="item.type == 'order_form'" [sliceData]="item"></app-order-slice>
        <app-hero-slice *ngIf="item.type == 'hero'" [sliceData]="item"></app-hero-slice>

        <app-custom-content *ngIf="item.type == 'custom_content' && item.id !== 'faq_links'" [sliceData]="item">
        </app-custom-content>
        <app-faq-landing-page *ngIf="item.type == 'faq'" [sliceData]="item"></app-faq-landing-page>

        <app-video *ngIf="item.type == 'video'" [sliceData]="item"></app-video>
        <app-show-url-parameter-slice *ngIf="item.type == 'show-url-parameter'" [sliceData]="item">
        </app-show-url-parameter-slice>
        <app-order-confirmation-slice *ngIf="item.type == 'order_confirmation'" [sliceData]="item">
        </app-order-confirmation-slice>
      </div>
    </div>
  </div>

  <div class="page no-gradient">
    <app-site-footer></app-site-footer>
  </div>
</div>
