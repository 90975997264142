<div class="slice full-width bigly-spacing" [attr.id]="sliceData.id">
  <div class="content">
    <h2 class="page-centered-title">{{ sliceData.title }}</h2>
    <div class="horizontal-option-line"></div>
    <div class="pricing-comparison">
      <div
        class="monthly"
        appInView
        (enterView)="monthlyInView = true"
        [@feature]="monthlyInView ? 'inview' : 'outofview'"
      >
        <div class="best-offer">{{ "BESTOFFER" | translate }}</div>
        <span class="duration">{{ "MONTHLY" | translate }}</span>
        <span
          class="description"
          [innerHTML]="sliceData.monthlyPriceText"
        ></span>
        <div class="price">
          {{ sliceData.monthlyPrice }}<sup>{{ "CHFPERMONTH" | translate }}</sup>
        </div>
      </div>
      <div class="splitter" appInView (enterView)="splitterInView = true">
        <div
          class="splitter-line"
          [@splitter]="splitterInView ? 'inview' : 'outofview'"
        ></div>
      </div>
      <ng-template [ngIf]="!!sliceData.dataPrice">
        <div
          class="data"
          appInView
          (enterView)="dataInView = true"
          [@feature]="dataInView ? 'inview' : 'outofview'"
        >
          <span class="duration">{{ "DATA" | translate }}</span>
          <span
            class="description"
            [innerHTML]="sliceData.dataPriceText"
          ></span>
          <div class="price">
            {{ sliceData.dataPrice
            }}<small>{{ "CHFPERMONTH" | translate }}</small>
          </div>
        </div>
      </ng-template>
      <ng-template [ngIf]="!sliceData.dataPrice">
        <div
          class="daily"
          appInView
          (enterView)="dailyInView = true"
          [@feature]="dailyInView ? 'inview' : 'outofview'"
        >
          <span class="duration">{{ "DATA" | translate }}</span>
          <span
            class="description"
            [innerHTML]="sliceData.dataPriceText"
          ></span>
          <div class="price">
            {{ sliceData.dailyPrice
            }}<small>{{ "CHFPERDAY" | translate }}</small>
          </div>
        </div>
      </ng-template>
    </div>
    <div
      *ngIf="!isRoamingPath"
      appInView
      (enterView)="paymentMethodsInView = true"
      [@feature]="paymentMethodsInView ? 'inview' : 'outofview'"
    >
      <div class="more-options">
        <div class="international-option">
          <span class="option-tag">
            {{ sliceData.internationalTag }}
          </span>
          <div
            class="option-name"
            [innerHTML]="sliceData.internationalHeader"
          ></div>
          <img
            class="info-button"
            src="assets/icons/info-primary.svg"
            (click)="openModal(sliceData.internationalText, 'international')"
          />
        </div>
        <div class="five_g-option">
          <span class="option-tag">{{ sliceData.fiveGTag }}</span>
          <div class="option-name" [innerHTML]="sliceData.fiveGHeader"></div>
          <img
            class="info-button"
            src="assets/icons/info-primary.svg"
            (click)="openModal(sliceData.fiveGText, 'fiveG')"
          />
        </div>
        <!--
        <div
          class="option-availability"
          [innerHTML]="sliceData.tooltipText"
        ></div>
        -->
      </div>
    </div>
    <img
      class="background-logo"
      src="/assets/icons/swype-pricing-background-logo.png"
      alt=""
    />
    <img
      class="background-logo"
      src="/assets/icons/swype-pricing-background-logo.png"
      alt=""
    />
  </div>
</div>

<app-modal
  *ngIf="isModalOpen"
  (isModalOpen)="hideModal($event)"
  [content]="modalContent"
  [modalClass]="modalClass"
>
</app-modal>
