<div class="slice full-height-2" id="{{ sliceData.id }}">
  <div class="content center-content">
    <div *ngIf="!isDesktop" [@form]="showForm ? 'shown' : 'hidden'" class="mobile-signup-container"
      [ngClass]="{'two-buttons': sliceData.showAppDownloadButtonOnMobile}">
      <button (click)="tracking.ctaClick('website order started')" routerLink="/{{lang.current}}/order"
        class="button full-width headless bottomless" [ngClass]="{'big':  !sliceData.showAppDownloadButtonOnMobile}">
        {{'TRY_FOR_FREE' | translate}}
      </button>
      <app-download-button *ngIf="!isDesktop && sliceData.showAppDownloadButtonOnMobile"
        [mobileCtaText]="sliceData.appDownloadButtonMobileText" [buttonExtraClass]="'headless bottomless white'">
      </app-download-button>
    </div>
    <div class="intro-container content-splitter reversed with-phone dark-bg">
      <div class="split-content align-content-vertically-at-start top-spacing"
        [@pageContent]="hide ? 'hidden' : 'shown'">
        <div class="page-intro center-text-on-mobile" [@content]="showContent ? 'shown' : 'hidden'">
          <p class="page-slogan">
            <span>{{'MOBILE_JUST_BETTER' | translate}}</span>
          </p>
          <h1 class="page-title" [innerHTML]="sliceData.title"></h1>
          <p class="page-claim bigger">
            <img class="page-checkmark" src="/assets/icons/checkmark-icon-intro.svg" alt="checkmark">
            <span>{{ sliceData.featureText }}</span>
          </p>
          <div class="line-separator"></div>
          <div class="home-points-with-icons">
            <div class="point-with-icon">
              <div class="icon">
                <img src="/assets/icons/wifi.png" class="icon-wifi" alt="">
              </div>
              <span>{{'HOMEPOINT1' | translate}}</span>
            </div>
            <div class="point-with-icon">
              <div class="icon">
                <img src="/assets/icons/phone.png" class="icon-phone" alt="">
              </div>
              <span>{{'HOMEPOINT2' | translate}}</span>
            </div>
            <div class="point-with-icon">
              <div class="icon">
                <img src="/assets/icons/swype-icon-red.png" class="icon-swype" alt="">
              </div>
              <span>{{'HOMEPOINT3' | translate}}</span>
            </div>
          </div>
          <div class="line-separator"></div>
        </div>
        <button *ngIf="isDesktop" [@form]="showForm ? 'shown' : 'hidden'" (click)="tracking.ctaClick('order now')"
          routerLink="/{{lang.current}}/order" class="button full-width big headless bottomless cta-button">
          <img src="/assets/icons/icon_SIM.svg" alt="">
          <span *ngIf="!isUserLogged">{{'TRY_FOR_FREE' | translate}}</span>
          <span *ngIf="isUserLogged">{{sliceData.ctaText}}</span>
        </button>
        <app-countdown *ngIf="sliceData.showCountdown" [endDates]="sliceData.countdownTo"></app-countdown>
      </div>
      <div class="split-content align-content-vertically-at-start app-phone-container"
        [@phone]="showPhone ? hide ? 'full' : 'shown' : 'hidden'">
        <app-phone></app-phone>
      </div>
    </div>
  </div>

  <div *ngIf="this.sliceData.scrollTarget" class="scroll-indicator">
    <app-scroll-indicator (click)="scrollDown()"></app-scroll-indicator>
  </div>
</div>
