import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotifierService } from 'angular-notifier';
import { AmplifyService } from '../amplify/amplify.service';
import { WebAppService } from '../api-client';

@Component({
  selector: 'sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
  valid: boolean;
  email: string = '';
  password: string = '';
  loading: boolean = false;
  lang: string = '';

  constructor(
    private webAppService: WebAppService,
    private amplifyService: AmplifyService,
    private router: Router,
    private notifier: NotifierService,
    private route: ActivatedRoute,
    private translate: TranslateService
  ) {}

  validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  checkValidity() {
    this.valid = this.validateEmail(this.email) && this.password.length > 1;
  }

  onPaste(event: ClipboardEvent, field) {
    this[field] = event.clipboardData.getData('text');
    this.checkValidity();
  }

  onEmailInput(event) {
    this.email = event.target.value;
    this.checkValidity();
  }

  onPasswordInput(event) {
    this.password = event.target.value;
    this.checkValidity();
  }

  returnToForgotPassword() {
    this.router.navigate(['/' + this.lang + '/forgot-password']);
  }

  async signIn(event) {
    if (!this.valid) {
      return;
    }
    this.loading = true;

    const result = await this.amplifyService.signIn(this.email, this.password);
    if (result === 'UserNotConfirmedException') {
      this.loading = false;
      this.notifier.notify(
        'error',
        this.translate.instant('ACCOUNT_NOT_CONFIRMED')
      );
      setTimeout(() => {
        this.router.navigate(['/' + this.lang + '/email-verification']);
      }, 2000);
      return;
    }

    const user = await this.amplifyService.getCurrentUser();

    if (!user) {
      this.loading = false;
      this.notifier.notify(
        'error',
        this.translate.instant('INCORRECT_EMAIL_OR_PASSWORD')
      );
      return;
    }

    this.router.navigate(['/' + this.lang + '/dashboard']);
  }

  ngOnInit(): void {
    this.valid = false;
    this.lang = this.route.snapshot.params['lang'];

    this.amplifyService.getCurrentUser().then(user => {
      if (user) {
        this.router.navigate(['/' + this.lang + '/dashboard']);
      }
    });
  }
}
