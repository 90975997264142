<div class="page-wrapper">
  <div class="page-content">
    <app-site-header></app-site-header>
    <notifier-container></notifier-container>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6 disappear">
          <img class="phone-picture-background" src="/assets/icons/swype-phone-image-background.png" alt="" />
          <img class="phone-picture" src="/assets/icons/dashboard-phone-image.png" alt="" />
          <img class="spiral-line" [ngClass]="{'hidden':suspendedAccount}" src="../../assets/icons/spiral_line.svg" alt="" />
        </div>
        <div class="col-md-12 col-lg-6" [ngClass]="{'suspended':suspendedAccount}">
          <app-suspended-account *ngIf="suspendedAccount; else notSuspended" [lang]="lang" [suspensionDate]="suspensionDate"></app-suspended-account>
        </div>
      </div>
    </div>
  </div>
  <app-help-button></app-help-button>
  <div class="page no-gradient">
    <app-site-footer></app-site-footer>
  </div>
</div>
<spinner-overlay [showspinner]="loading"></spinner-overlay>

<ng-template #notSuspended>
  <div style="height: 100vh; width: 100%; position: relative; display: block;"
  *ngIf="userIsOnboarded === 'loading'"></div>
  <app-onboarding-steps *ngIf="!userIsOnboarded && (userIsOnboarded !== 'loading')" [activeStep]="activeStep" [onBoardingStep]="onBoardingStep"></app-onboarding-steps>
  <app-user-subscriptions *ngIf="userIsOnboarded && (userIsOnboarded !== 'loading')" [userSubscriptions]="userSubscriptions" (simNumberToBlock)="blockSim($event)" (simNumberToUnblock)="unblockSim($event)"></app-user-subscriptions>
</ng-template>