import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-unblock-sim',
  templateUrl: './unblock-sim.component.html',
  styleUrls: ['./unblock-sim.component.scss'],
  animations: [
    trigger('showWarning', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateX(100%)' }),
        animate(
          '450ms ease-in-out',
          style({ opacity: 1, transform: 'translateX(0)' })
        )
      ])
    ])
  ]
})
export class UnblockSimComponent implements OnInit {
  @Output()
  close = new EventEmitter<boolean>();

  @Output()
  simBlocked = new EventEmitter<boolean>();

  constructor() {}

  unblockSim() {
    this.simBlocked.emit(false);
    this.closePopup();
  }

  closePopup() {
    this.close.emit(true);
  }

  ngOnInit(): void {}
}
