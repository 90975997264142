import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../language.service';
import { OrderService } from '../order.service';
import { TrackingService } from '../tracking.service';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss']
})
export class ThankYouComponent implements OnInit {
  loading: boolean = false;
  public order: any;

  constructor(
    private tracking: TrackingService,
    public lang: LanguageService,
    private orderService: OrderService
  ) {}

  ngOnInit(): void {
    this.orderService.order$.subscribe(data => {
      this.order = {
        firstName: data.orderData.firstName,
        lastName: data.orderData.lastName,
        city: data.orderData.city,
        street: data.orderData.street,
        number: data.orderData.number,
        plz: data.orderData.plz,
        esim: data.esim && data.esim !== 'false'
      };
    });
  }

  openAppStore(store: 'google' | 'apple') {
    this.tracking.ctaClick('download link ' + store);
    setTimeout(() => {
      let link = this.tracking.getDownloadLinkForStore(store);
      window.open(link, '_blank');
    }, 250);
  }
}