<div class="page-wrapper">
  <div class="page-content">
    <app-site-header></app-site-header>
    <notifier-container></notifier-container>
    <div class="container-fluid">
      <div class="title">
        <div class="main-title">{{ "SIGN_IN_TO_SWYPE" | translate }}</div>
        <div class="subtitle">{{ "ENTER_DETAILS" | translate }}</div>
      </div>
      <social-login></social-login>
      <div class="form-container">
        <text-field
          [authentication]="true"
          [disabled]="false"
          (input)="onEmailInput($event)"
          [placeholder]="'you@example.com'"
          [labelText]="'EMAIL_ADDRESS' | translate"
        >
        </text-field>
        <text-field
          [authentication]="true"
          type="password"
          (input)="onPasswordInput($event)"
          [disabled]="false"
          [placeholder]="'ENTER_YOUR_PASSWORD' | translate"
          [labelText]="'PASSWORD' | translate"
          (keyup.enter)="signIn($event)"
        >
        </text-field>
        <button (click)="returnToForgotPassword()" class="forgot-password">
          {{ "FORGOT_YOUR_PASSWORD" | translate }}?
          <img
            class="arrow"
            src="assets/icons/forgot-password-arrow.svg"
            alt="forgot password arrow"
          />
        </button>
        <a (click)="signIn($event)">
          <button class="sign-in-button" [ngClass]="{ active: valid }">
            {{ "SIGN_IN_BTN" | translate }}
          </button>
        </a>
      </div>
      <!-- <social-login></social-login> -->
    </div>
  </div>
  <app-help-button></app-help-button>
  <div class="page no-gradient">
    <app-site-footer></app-site-footer>
  </div>
</div>
<spinner-overlay [showspinner]="loading"></spinner-overlay>
