<div class="social-login-container">
  <button (click)="loginUsingGoogle($event)" class="google">
    <img src="assets/icons/google-button.svg" alt="google logo" class="google-icon" />
    <span class="button-text">Sign in with Google</span>
  </button>
  <button (click)="loginUsingFacebook($event)" class="small-social-button">
    <img src="assets/icons/facebook-button.svg" alt="facebook logo" class="fsmall-social-logo" />
  </button>
  <!-- <button class="small-social-button">
    <img
      src="assets/icons/apple-button.svg"
      alt="apple logo"
      class="fsmall-social-logo"
    />
  </button> -->
  <or-divider></or-divider>
</div>
