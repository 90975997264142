import { Injectable } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CampaignService } from './campaign.service';
import { StorageEntryConfig } from './storage-entry-config.class';
import { StorageService } from './storage.service';
import { NavigationEnd } from '@angular/router';
import MD5 from 'crypto-js/md5';
import Hex from 'crypto-js/enc-hex';
import { Visitor } from './interfaces/visitor';
import { AmplifyService } from './amplify/amplify.service';
import { WebAppService } from './api-client';

@Injectable({
  providedIn: 'root'
})
export class TrackingService {
  public static readonly EVENT_VIRTUAL_PAGE_VIEW = 'virtual page view';
  public static readonly EVENT_VISITOR_INTERACTION = 'gtm visitor interaction';
  public static readonly EVENT_OPTIMIZE_ACTIVATE = 'optimize.activate';

  public static readonly VI_NAME_BUTTON_INTERACTION = 'button interaction';
  public static readonly VI_NAME_FORM_TRACKING = 'form tracking';
  public static readonly VI_NAME_404 = 'page not found';
  public static readonly VI_NAME_FORM_SUBMIT = 'form submit';
  public static readonly VI_NAME_FORM_ERROR = 'form error';

  constructor(
    private storage: StorageService,
    private influencer: CampaignService,
    private amplifyService: AmplifyService,
    private webAppService: WebAppService
  ) {
    window.dataLayer = window.dataLayer || [];
  }

  getDownloadLink() {
    const influencerName = this.storage.get(
      StorageEntryConfig.INFLUENCER_CAMPAIGN
    );
    if (influencerName && this.influencer[influencerName]) {
      return this.influencer[influencerName].downloadLink;
    }
    return 'https://app.swype.ch';
  }

  getDownloadLinkForStore(store: 'google' | 'apple') {
    if (store === 'apple') {
      return 'https://apps.apple.com/ch/app/swype-mobile-just-better/id1479174713';
    } else if (store === 'google') {
      return 'https://play.google.com/store/apps/details?id=ch.swype';
    } else {
      return 'https://app.swype.ch';
    }
  }

  async createVisitor(): Promise<Visitor> {
    const user = await this.amplifyService.getCurrentUser();

    if (!user) {
      return {
        status: this.storage.get(StorageEntryConfig.USER_LOGGED_ONCE)
          ? 'logged_out'
          : 'anonymous',
        user: {
          id: undefined,
          address_exists: false
        }
      };
    }

    this.storage.save(StorageEntryConfig.USER_LOGGED_ONCE, 'true');

    const data: any = await (
      await this.webAppService.webUsersCognitoIdGet(user.attributes.sub)
    ).toPromise();

    return {
      status: 'logged_in',
      user: {
        id: MD5(user.attributes.sub).toString(Hex),
        address_exists: !!(data.data.address1 && data.data.address1.length)
      }
    };
  }

  async ctaClick(name: string) {
    const influencer = this.storage.get(StorageEntryConfig.INFLUENCER_CAMPAIGN);
    const trackInfluencer =
      ['order now', 'pre order'].includes(name) && influencer;
    window.dataLayer.push({
      event: TrackingService.EVENT_VISITOR_INTERACTION,
      interaction_name: TrackingService.VI_NAME_BUTTON_INTERACTION,
      button_action: trackInfluencer ? name + ' ' + influencer : name,
      visitor: await this.createVisitor()
    });
  }

  async formFieldBlur(form: string, field: string) {
    window.dataLayer.push({
      event: TrackingService.EVENT_VISITOR_INTERACTION,
      interaction_name: TrackingService.VI_NAME_FORM_TRACKING,
      form_name: form,
      form_field: field,
      visitor: await this.createVisitor()
    });
  }

  async pageNotFound(path) {
    window.dataLayer.push({
      event: TrackingService.EVENT_VISITOR_INTERACTION,
      interaction_name: TrackingService.VI_NAME_404,
      event_action: path,
      event_label: document.referrer || '',
      visitor: await this.createVisitor()
    });
  }

  async submitForm(formName: string, form: NgForm, error: string = '') {
    if (!form.form.valid && !error) {
      error = Object.keys(form.form.controls)
        .map(key => {
          const control = form.form.controls[key];
          const errors = control.errors ? Object.keys(control.errors) : [];
          if (errors.length) {
            return key;
          }
        })
        .filter(c => c)
        .join(',');
    }

    window.dataLayer.push({
      event: TrackingService.EVENT_VISITOR_INTERACTION,
      interaction_name: TrackingService.VI_NAME_FORM_SUBMIT,
      form_name: formName,
      form_submission: !error ? 'successful' : 'not successful',
      visitor: await this.createVisitor()
    });

    if (error) {
      window.dataLayer.push({
        event: TrackingService.EVENT_VISITOR_INTERACTION,
        interaction_name: TrackingService.VI_NAME_FORM_ERROR,
        form_name: formName,
        form_field: error,
        form_message: '',
        visitor: await this.createVisitor()
      });
    }
  }

  async triggerVirtualPageView(event: NavigationEnd) {
    // get rid of locale
    const path = event.urlAfterRedirects.substring(3) || '/';

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: TrackingService.EVENT_VIRTUAL_PAGE_VIEW,
      page_name: path.split('#').join('/'),
      page_referrer: window.location.href || document.referrer,
      page_url: window.location.href,
      visitor: await this.createVisitor()
    });
  }

  async triggerOptimizeActivateEvent() {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: TrackingService.EVENT_OPTIMIZE_ACTIVATE,
      visitor: await this.createVisitor()
    });
  }
}
